import React, { Fragment, useState } from "react";
import { signal } from "@preact/signals-react";

import {
  CheckCircleIcon,
  EyeIcon,
  PlusCircleIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import { FormattedMessage as FM } from "react-intl";
import GalleryImagesRoom from "./GalleryImagesRoom";
import GalleryItem from "components/Tools/GalleryItem";
import IntegerField from "./IntegerField";
const imagesGalleryRoom = signal([]);

const ItemClassification = ({
  dataRooms,
  handleSelectClassification,
  imgDefault,
  colors,
}) => {
  const [roomValues, setRoomValues] = useState({});
  const bgColorSecundary = colors?.colorSecundary || "bg-yellow-500";
  const bgColorPrimary = colors?.colorPrimary || "bg-blue-presik";
  const textColorPrimary = colors?.colorTextPrimary || "text-yellow-500";
  const AmenitiesItem = ({ data, key }) => {
    return (
      <div
        key={key}
        className="flex space-x-1 items-center max-w-max text-left text-[9px] mt-5"
      >
        <span>
          <CheckCircleIcon className="w-5 relative -top-[1px]" />
        </span>
        <span className="max-w-max">{data.name}</span>
      </div>
    );
  };

  const handleInputChange = (name, value, max) => {
    const [type, categoryId] = name.split("-");
    const otherType = type === "adults" ? "children" : "adults";
    const otherValue = roomValues[categoryId]?.[otherType] || 0;

    if (value + otherValue <= max) {
      setRoomValues((prevState) => ({
        ...prevState,
        [categoryId]: {
          ...prevState[categoryId],
          [type]: value,
        },
      }));
    }
  };

  const ButtomBooking = ({ category }) => {
    const adultsValue = roomValues[category.id]?.adults || 0;
    const childrenValue = roomValues[category.id]?.children || 0;

    const handleBooking = () => {
      handleSelectClassification({
        ...category,
        adults: adultsValue,
        children: childrenValue,
      });
    };

    return (
      <div className="md:mt-4 mb-5 md:mb-0 flex justify-center md:justify-end  md:absolute md:bottom-0 right-0">
        <button
          className={`group  px-5 pt-2  uppercase ${textColorPrimary} flex space-x-2 ${
            "hover:" + bgColorSecundary
          }  hover:text-yellow-500 transition-all duration-300 ${bgColorPrimary}`}
          onClick={handleBooking}
        >
          <span className="text-sm">
            <FM id="app.booking.btn_reserve_item" />{" "}
          </span>
          <PlusCircleIcon
            className={`w-7  group-hover:text-white  transition-all duration-300 relative -top-1 ${textColorPrimary}`}
          />
        </button>
      </div>
    );
  };

  return (
    <Fragment>
      <div className=" grid grid-cols-2 gap-10 mb-10  md:mb-20">
        {dataRooms &&
          dataRooms.map((category) => (
            <div
              key={category.id}
              className="md:flex border border-gray-100 shadow-sm rounded-md bg-white relative cursor-pointer col-span-2 overflow-hidden"
            >
              <div
                className="md:w-[36%] w-full relative group"
                onClick={() =>
                  (imagesGalleryRoom.value = category?.product.images)
                }
              >
                <div className=" absolute w-full h-72 top-0  justify-center items-center hidden group-hover:flex transform transition-all duration-500  z-20">
                  <EyeIcon className="w-10 h-10 text-white" />
                </div>
                <img
                  src={category?.product.images[0] || imgDefault}
                  className="h-72  object-cover w-full "
                />
              </div>
              <div className="md:pb-5 flex flex-col px-5 py-5 items-stretch  md:w-[65%] w-full relative">
                <h2 className="font-medium mb-4">{category.product.name}</h2>
                <div className="flex md:flex-col md:block md:justify-around">
                  <div className="md:flex md:divide-x md:divide-gray-200 md:space-x-10 text-gray-600  md:w-full   w-[89%]">
                    <span className="flex flex-col ">
                      <b>
                        <FM id="web.booking.occupation" />
                      </b>
                      <span className="flex">
                        <UserIcon className="w-4" />{" "}
                        {category.rooms[0].max_accommodation}
                      </span>
                    </span>
                    <span className="md:pl-3 flex flex-col">
                      <b>
                        <FM id="web.booking.minimum_stay" />
                      </b>
                      <span>
                        1 <FM id="app.booking.night" />
                      </span>
                    </span>
                    <span className="md:pl-3 flex flex-col">
                      <b>
                        <FM id="app.booking.includes" />
                      </b>
                      <span>
                        <FM id="hotel.folio.breakfast_included" />
                      </span>
                    </span>
                  </div>

                  <GalleryItem
                    data={category.rooms[0].amenities}
                    renderItemComponent={<AmenitiesItem />}
                    numberItem={{ desk: 6, mobile: 2 }}
                    mode="default"
                    style="flex space-x-4 w-full"
                    indicator={false}
                    mask={true}
                  />
                </div>
                <div className="md:flex md:space-x-7 space-y-4 md:space-y-0 items-center w-full border-t border-gray-200 pt-5 md:-mt-3 md:pb-7 pb-3">
                  <div className="font-semibold text-lg uppercase flex flex-col">
                    <span>
                      <FM id="app.booking.price" /> $
                      {category.sale_price.toLocaleString("es-ES")}
                    </span>
                    <span className="text-[10px] block font-normal space-x-1">
                      <span>
                        <FM id="web.booking.txt_iva" />
                      </span>
                      <span className="font-medium">
                        {(
                          category.sale_price_taxed - category.sale_price
                        ).toLocaleString("es-ES")}
                      </span>
                    </span>
                  </div>

                  <div className="flex space-x-3 max-w-[290px] relative">
                    <IntegerField
                      field={{
                        name: `adults-${category.id}`,
                        readOnly: false,
                        color: "white",
                        label: "hotel.folio.num_adults",
                      }}
                      data={{ value: roomValues[category.id]?.adults || 1 }}
                      attrs={{ level: "wizard" }}
                      onChange={handleInputChange}
                      max={category.rooms[0].max_accommodation}
                    />
                    <IntegerField
                      field={{
                        name: `children-${category.id}`,
                        readOnly: false,
                        color: "white",
                        label: "hotel.folio.num_children",
                      }}
                      data={{ value: roomValues[category.id]?.children || 0 }}
                      attrs={{ level: "wizard" }}
                      onChange={handleInputChange}
                      max={category.rooms[0].max_accommodation}
                    />
                  </div>
                </div>
              </div>
              <ButtomBooking category={category} />
            </div>
          ))}
      </div>
      <GalleryImagesRoom
        show={imagesGalleryRoom.value.length !== 0}
        dataImages={imagesGalleryRoom.value}
        onClose={() => (imagesGalleryRoom.value = [])}
      />
    </Fragment>
  );
};

export default ItemClassification;
