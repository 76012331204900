import React, { useState, useEffect } from "react";
import ModelActivity from "./ModelActivity";
import proxy from "api/proxy";
import date from "date-and-time";
import ItemCard from "./components/ItemCard";
import { SearchActivities } from "./components/SearchActivities";
import DateRangeFilter from "components/Tools/DateRangeFilter";
import PureModal from "components/Modals/PureModal";
import ModalWizzardActivityInformation from "./components/ModalWizzardActivityInformation";

const ActivityInformation = (props) => {
  const dayToday = new Date();
  const { config } = props;
  const ctxViewActi = ModelActivity.ctxView(config);
  let [activityData, setActivityData] = useState([]);
  const [originalActivityData, setOriginalActivityData] = useState([]);
  const [filterDay, setFilterDay] = useState(dayToday);
  let [onOpen, setOnOPen] = useState(false);
  let [idSchedule, setIdSchedule] = useState(null);
  let day = new Date();
  let dayFormatted = date.format(day, "YYYY-MM-DD");
  let dayFormattedEnd = date.format(day, "YYYY-MM-DD");

  const handleChangeDate = (value) => {
    let daySelected = new Date(value);
    daySelected.setDate(daySelected.getDate() + 1);
    let _daySelected = date.format(new Date(daySelected), "YYYY-MM-DD");
    setFilterDay(_daySelected);
  };

  async function getActivity() {
    let day = new Date(filterDay);
    let dayFormatted = date.format(day, "YYYY-MM-DD");
    let dom = [["date_activity", "=", dayFormatted]];
    const fields = [
      "kind",
      "kind.name",
      "kind.image",
      "kind.code",
      "kind.employee.party.name",
      "kind.product.list_price",
      "kind.product.sale_price_w_tax",
      "kind.product.image_url",
      "kind.product.images",
      "date_activity",
      "activities",
      "activities.quota",
      "activities.available",
      "activities.state",
      "id",
    ];
    const { data: res } = await proxy.search(
      "sale_activity.schedule",
      dom,
      fields,
    );
    if (res) {
      const res_ = res.map((activity) => ({
        id: activity.id,
        name: activity["kind."].name ?? "",
        date_activity: activity.date_activity,
        kind: activity["kind."].id,
        activities: activity["activities."],
        // price: activity["kind."]?.["product."].list_price,
        price: activity["kind."]?.["product."].sale_price_w_tax,
        image: null,
        img: activity["kind."]?.image,
      }));
      console.log(res);
      setActivityData(res_);
      setOriginalActivityData(res_);
    }
  }

  const resetActivityData = () => {
    setActivityData(originalActivityData);
  };

  const handleChangeActivity = (id) => {
    setIdSchedule(id);
    setOnOPen(true);
  };
  useEffect(() => {
    getActivity();
  }, [filterDay]);

  return (
    <div id="board" className="mx-4 my-3 w-full mt-10 px-8 ">
      <DateRangeFilter action={handleChangeDate} />
      <hr className="mt-10 mb-10" />
      {/* <Board ctxView={ctxView} /> */}
      <SearchActivities
        data={activityData}
        setActivityData={setActivityData}
        resetActivityData={resetActivityData}
      />
      <div className="grid grid-cols-4 gap-3 mt-10">
        {activityData.map((activity, key) => (
          <ItemCard
            activity={activity}
            keyId={key}
            key={key}
            handleChangeActivity={handleChangeActivity}
            dayFormatted={dayFormatted}
            dayFormattedEnd={dayFormattedEnd}
          />
        ))}
      </div>
      {/* 
      <ModalWizardActi
        key="button_wizard"
        model="sale_activity.activity"
        label={"Horarios de actividades"}
        ctxView={ctxViewActi}
        onAccept={() => console.log("asdsd")}
        onClose={() => setOnOPen(onOpen ? false : true)}
        // record={record}
        // view={view}
        Component={ModalWizzardActivity}
        onOpen={onOpen}
      /> */}
      <PureModal open={onOpen} onClose={() => setOnOPen(onOpen ? false : true)}>
        <ModalWizzardActivityInformation
          key="button_wizard"
          model="sale_activity.activity"
          ctxView={ctxViewActi}
          onAccept={() => console.log("asdsd")}
          onClose={() => setOnOPen(onOpen ? false : true)}
          idSchedule={idSchedule}
          // record={record}
          // view={view}
          onOpen={onOpen}
        />
      </PureModal>
    </div>
  );
};

export default ActivityInformation;
