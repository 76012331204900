import React from "react";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/20/solid";

import upStore from "store/upStore";
import { classNames } from "tools/ui";

const iconStyle = "w-5 h-5 text-stone-700";
const stBtn =
  "border-slate-300 border-[1px] px-3 py-1 bg-zinc-100 active:bg-zinc-200 focus:outline-none";
const base =
  "rounded-none w-full border-t text-center border-b border-slate-300 py-2 px-3 text-gray-700 focus:outline-none focus:border focus:border-cyan-500 ";
const _color = "bg-gray-100";

const leftBtn = "rounded-l-md";
const rightBtn = "rounded-r-md";

function IntegerCell({
  name,
  readOnly,
  value,
  record,
  onChange,
  field,
  data,
  level,
  parentRec,
}) {
  function onChangeInput(event) {
    let _value = event.target.value;
    _value = _value.toString().replace(/\./g, "");
    if (isNaN(_value)) {
      return;
    }
    data.value = _value;
    const args = {
      field,
      parentRec,
      recId: record.id,
      fieldName: field.name,
      value: _value,
    };
    upStore(level, args);
  }

  function onChangeValue(sign) {
    if (sign === "+") {
      data.value = data.value + 1;
    } else if (data.value > 0) {
      data.value -= 1;
    }
    const args = {
      field,
      parentRec,
      recId: record.id,
      fieldName: field.name,
      value: data.value,
    };
    upStore(level, args);
  }

  return (
    <div className="flex items-stretch w-full max-w-[140px] mx-auto">
      {!readOnly && (
        <button
          className={classNames(stBtn, leftBtn)}
          onClick={() => onChangeValue("-")}
        >
          <MinusCircleIcon className={iconStyle} />
        </button>
      )}
      <input
        id={name}
        name={name}
        className={classNames(base, _color)}
        type="text"
        onChange={onChangeInput}
        value={data}
        readOnly={false}
      />
      {!readOnly && (
        <button
          onClick={() => onChangeValue("+")}
          className={classNames(stBtn, rightBtn)}
        >
          <PlusCircleIcon className={iconStyle} />
        </button>
      )}
    </div>
  );
}

export default IntegerCell;
