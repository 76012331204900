const hotels = {
  HOTELTERRA: {
    colorPrimary: "bg-[#0e2d1b]",
    colorTextPrimary: "text-[#ff8D6D]",
    colorSecundary: "bg-[#ff8D6D]",
    privacyPolicies: "https://terrabiohotel.com/politica-de-privacidad/",
    dataProtection: "https://terrabiohotel.com/proteccion-de-datos/",
    callToActions: "text-white",
  },
  HOTELTERRALOCAL2: {
    colorPrimary: "bg-[#0e2d1b]",
    colorTextPrimary: "text-[#ff8D6D]",
    colorSecundary: "bg-[#ff8D6D]",
    privacyPolicies: "https://terrabiohotel.com/politica-de-privacidad/",
    dataProtection: "https://terrabiohotel.com/proteccion-de-datos/",
    callToActions: "text-white",
  },
  ARISHA: {
    colorPrimary: "bg-[#181818]",
    colorTextPrimary: "text-white",
    colorSecundary: "bg-[#a14a25]",
    privacyPolicies: "#",
    dataProtection: "#",
    grayScale: true,
    callToActions: "text-white",
  },
};

export default hotels;
