import React from "react";

const IconsWifi = ({ size = "2em", fill = "black" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 640 512"
      fill={fill}
    >
      <path d="M54.2 202.9C123.2 136.7 216.8 96 320 96s196.8 40.7 265.8 106.9c12.8 12.2 33 11.8 45.2-.9s11.8-33-.9-45.2C549.7 79.5 440.4 32 320 32S90.3 79.5 9.8 156.7C-2.9 169-3.3 189.2 8.9 202s32.5 13.2 45.2 .9zM320 256c56.8 0 108.6 21.1 148.2 56c13.3 11.7 33.5 10.4 45.2-2.8s10.4-33.5-2.8-45.2C459.8 219.2 393 192 320 192s-139.8 27.2-190.5 72c-13.3 11.7-14.5 31.9-2.8 45.2s31.9 14.5 45.2 2.8c39.5-34.9 91.3-56 148.2-56zm64 160a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z" />
    </svg>
  );
};

const IconBed = ({ size = "2em", fill = "black" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 640 512"
      fill={fill}
    >
      <path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" />
    </svg>
  );
};

const IconParking = ({ size = "2em", fill = "black" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 640 512"
      fill={fill}
      className="relative left-3"
    >
      <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM192 256h48c17.7 0 32-14.3 32-32s-14.3-32-32-32H192v64zm48 64H192v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V288 168c0-22.1 17.9-40 40-40h72c53 0 96 43 96 96s-43 96-96 96z" />
    </svg>
  );
};

const IconAir = ({ size = "2em", fill = "black" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 640 512"
      fill={fill}
      className="relative left-3"
    >
      <path d="M288 32c0 17.7 14.3 32 32 32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H352c53 0 96-43 96-96s-43-96-96-96H320c-17.7 0-32 14.3-32 32zm64 352c0 17.7 14.3 32 32 32h32c53 0 96-43 96-96s-43-96-96-96H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H384c-17.7 0-32 14.3-32 32zM128 512h32c53 0 96-43 96-96s-43-96-96-96H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H160c17.7 0 32 14.3 32 32s-14.3 32-32 32H128c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
    </svg>
  );
};

const Icon247 = ({ size = "2em", fill = "black" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 512 512"
      fill={fill}
    >
      <path d="M216 64c-13.3 0-24 10.7-24 24s10.7 24 24 24h16v33.3C119.6 157.2 32 252.4 32 368H480c0-115.6-87.6-210.8-200-222.7V112h16c13.3 0 24-10.7 24-24s-10.7-24-24-24H256 216zM24 400c-13.3 0-24 10.7-24 24s10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H24z" />
    </svg>
  );
};

const IconsTree = ({ size = "2em", fill = "black" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      fill={fill}
      viewBox="0 0 640 512"
    >
      <path d="M288 48c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48V192h40V120c0-13.3 10.7-24 24-24s24 10.7 24 24v72h24c26.5 0 48 21.5 48 48V464c0 26.5-21.5 48-48 48H432 336c-26.5 0-48-21.5-48-48V48zm64 32v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16H368c-8.8 0-16 7.2-16 16zm16 80c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H368zM352 272v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H368c-8.8 0-16 7.2-16 16zm176-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H528zM512 368v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16H528c-8.8 0-16 7.2-16 16zM224 160c0 6-1 11-2 16c20 14 34 38 34 64c0 45-36 80-80 80H160V480c0 18-15 32-32 32c-18 0-32-14-32-32V320H80c-45 0-80-35-80-80c0-26 13-50 33-64c-1-5-1-10-1-16c0-53 42-96 96-96c53 0 96 43 96 96z" />
    </svg>
  );
};

const IconsPets = ({ size = "2em", fill = "black" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      fill={fill}
      viewBox="0 0 512 512"
    >
      <path d="M226.5 92.9c14.3 42.9-.3 86.2-32.6 96.8s-70.1-15.6-84.4-58.5s.3-86.2 32.6-96.8s70.1 15.6 84.4 58.5zM100.4 198.6c18.9 32.4 14.3 70.1-10.2 84.1s-59.7-.9-78.5-33.3S-2.7 179.3 21.8 165.3s59.7 .9 78.5 33.3zM69.2 401.2C121.6 259.9 214.7 224 256 224s134.4 35.9 186.8 177.2c3.6 9.7 5.2 20.1 5.2 30.5v1.6c0 25.8-20.9 46.7-46.7 46.7c-11.5 0-22.9-1.4-34-4.2l-88-22c-15.3-3.8-31.3-3.8-46.6 0l-88 22c-11.1 2.8-22.5 4.2-34 4.2C84.9 480 64 459.1 64 433.3v-1.6c0-10.4 1.6-20.8 5.2-30.5zM421.8 282.7c-24.5-14-29.1-51.7-10.2-84.1s54-47.3 78.5-33.3s29.1 51.7 10.2 84.1s-54 47.3-78.5 33.3zM310.1 189.7c-32.3-10.6-46.9-53.9-32.6-96.8s52.1-69.1 84.4-58.5s46.9 53.9 32.6 96.8s-52.1 69.1-84.4 58.5z" />
    </svg>
  );
};

const IconsFood = ({ size = "1em", fill = "black" }) => {
  return (
    <svg
      id="fi_7189680"
      enable-background="new 0 0 512 512"
      height="26"
      viewBox="0 0 512 512"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="256" cy="256" fill="#ff6d3c" r="256"></circle>
        <path
          d="m256 0c141.159 0 256 114.841 256 256s-114.841 256-256 256z"
          fill="#ff0020"
        ></path>
        <path
          d="m425.971 107.222-54.929 54.95c-5.857 5.858-15.355 5.859-21.213.004-5.859-5.856-5.861-15.354-.004-21.213l54.932-54.953c-39.777-34.852-91.84-56.01-148.757-56.01-124.617 0-226 101.383-226 226 0 56.927 21.166 108.998 56.029 148.778l54.929-54.949c5.855-5.86 15.354-5.861 21.213-.004 5.859 5.856 5.861 15.354.004 21.213l-54.932 54.952c39.778 34.852 91.84 56.01 148.757 56.01 124.617 0 226-101.383 226-226 0-56.927-21.165-108.998-56.029-148.778z"
          fill="#f0f6f8"
        ></path>
        <path
          d="m256 482c124.617 0 226-101.383 226-226 0-56.927-21.165-108.998-56.029-148.778l-54.929 54.95c-5.857 5.858-15.355 5.859-21.213.004-5.859-5.856-5.861-15.354-.004-21.213l54.932-54.953c-39.777-34.852-91.84-56.01-148.757-56.01z"
          fill="#d5e3f3"
        ></path>
        <g>
          <path
            d="m294.059 126.717-76.12-43.944c-4.397-2.537-9.814-2.538-14.211.001l-76.12 43.944c-4.398 2.538-7.108 7.231-7.108 12.309v87.895c0 5.078 2.709 9.771 7.108 12.309l76.12 43.944c4.397 2.539 9.814 2.538 14.211.001l76.12-43.944c4.398-2.538 7.108-7.231 7.108-12.309v-87.895c0-5.08-2.71-9.772-7.108-12.311z"
            fill="#bad1ec"
          ></path>
          <path
            d="m210.833 182.98v102.098c2.454 0 4.907-.635 7.105-1.903l76.12-43.944c4.398-2.538 7.108-7.231 7.108-12.309v-87.895c0-2.538-.677-4.979-1.902-7.103z"
            fill="#9fc0e7"
          ></path>
          <path
            d="m210.833 80.87c-2.454 0-4.907.635-7.106 1.904l-76.12 43.944c-2.199 1.269-3.976 3.077-5.203 5.202l88.428 51.059v-102.109z"
            fill="#d5e3f3"
          ></path>
          <path
            d="m384.392 272.769-76.12-43.944c-4.397-2.537-9.814-2.538-14.211.001l-76.12 43.944c-4.398 2.538-7.108 7.231-7.108 12.309v87.895c0 5.078 2.709 9.771 7.108 12.309l76.12 43.944c4.397 2.539 9.814 2.538 14.211.001l76.12-43.944c4.398-2.538 7.108-7.231 7.108-12.309v-87.895c0-5.08-2.709-9.772-7.108-12.311z"
            fill="#bad1ec"
          ></path>
          <path
            d="m301.167 329.033v102.097c2.454 0 4.907-.635 7.105-1.903l76.12-43.944c4.398-2.538 7.108-7.231 7.108-12.309v-87.895c0-2.538-.677-4.979-1.902-7.103z"
            fill="#9fc0e7"
          ></path>
          <path
            d="m301.167 226.922c-2.454 0-4.907.635-7.106 1.904l-76.12 43.944c-2.199 1.269-3.976 3.077-5.203 5.202l88.428 51.059v-102.109z"
            fill="#d5e3f3"
          ></path>
        </g>
      </g>
    </svg>
  );
};

const IconsNoGluten = () => {
  return (
    <svg
      clip-rule="evenodd"
      fill-rule="evenodd"
      height="26"
      image-rendering="optimizeQuality"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      viewBox="0 0 173.397 173.397"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
      id="fi_9588956"
    >
      <g id="Layer_x0020_1">
        <circle cx="86.699" cy="86.699" fill="#df574b" r="84.667"></circle>
        <g id="_466746072">
          <g>
            <path
              id="_466747104"
              d="m86.6985 141.372c-14.6021 0-28.3331-5.6873-38.6618-16.0111-10.3239-10.3289-16.0111-24.0594-16.0111-38.6619 0-14.6051 5.6872-28.3331 16.0111-38.6593 10.3289-10.3265 24.0594-16.0137 38.6618-16.0137 14.6027 0 28.3332 5.6873 38.6595 16.0137 10.3268 10.3237 16.0135 24.0543 16.0135 38.6593.0005 14.6024-5.6872 28.3331-16.0135 38.6619-10.326 10.3237-24.057 16.0111-38.6595 16.0111zm-33.6623-14.6933 73.6401-73.6397c7.6779 9.1016 12.3089 20.8492 12.3089 33.66.0005 28.8295-23.4572 52.2867-52.2867 52.2867-12.8105-.0001-24.5608-4.6315-33.6625-12.3069zm-4.5658-4.6945 73.5137-73.5113 2.9431 2.9382-73.5137 73.5164zm38.2281-87.5694c-28.829 0-52.2867 23.4522-52.2867 52.2842.0002 12.8108 4.6315 24.5609 12.307 33.6626l73.6427-73.6424c-9.102-7.6755-20.8546-12.3044-33.663-12.3044z"
            ></path>
            <path
              id="_466747632"
              d="m86.6985 138.27c-.6576 0-1.1943-.5341-1.1943-1.1943l.0003-45.7478c0-.6602.5339-1.1943 1.194-1.1943.6583-.0001 1.1945.5342 1.1945 1.1943l.0001 45.7477c0 .6602-.5368 1.1945-1.1946 1.1944zm0-55.0081c-.6576 0-1.1943-.5342-1.1943-1.1918l.0003-9.845c0-.6603.5339-1.1944 1.194-1.1944.6583-.0001 1.1945.5342 1.1945 1.1944l.0001 9.8448c0 .6578-.5368 1.192-1.1946 1.192z"
            ></path>
            <path
              id="_466747848"
              d="m76.022 77.4936c-.2948 0-.5947-.1133-.829-.3326-3.4544-3.3364-5.2842-8.0761-5.2842-13.7054.0002-.6603.5343-1.1944 1.1945-1.1944 7.507-.0001 12.7353 2.4266 15.5877 7.2218 4.2184-7.2193 12.4581-7.2218 18.6216-7.2218.6582-.0001 1.1944.5342 1.1944 1.1944.0001.6602-.5367 1.1919-1.1944 1.1919-8.1491 0-14.6151.5921-17.5028 8.008-.1786.4586-.6198.761-1.1113.761v-.0001c-.491 0-.9348-.3023-1.1112-.7609-1.9855-5.0925-6.3273-7.7107-13.2569-7.9828.2371 4.4273 1.7891 8.1315 4.5231 10.7698.4743.456.4865 1.212.0303 1.6858-.2341.2418-.5493.3653-.8618.3653z"
            ></path>
            <path
              id="_466747536"
              d="m79.4369 66.0485c-.0278 0-.0559 0-.0836-.0025-.6575-.0455-1.1542-.6174-1.1088-1.2725.4009-5.758 3.2053-14.2522 7.61-18.6569.2243-.2243.529-.3503.844-.3503.3153 0 .62.126.8417.3503 4.4073 4.4047 7.2118 12.899 7.6098 18.6569.0485.6551-.4484 1.2271-1.106 1.2725-.6575.0428-1.2272-.451-1.2751-1.1087-.3451-4.9943-2.6761-12.1154-6.0704-16.2051-3.3938 4.0897-5.725 11.2108-6.0727 16.2051-.0425.63-.567 1.1112-1.1889 1.1112z"
            ></path>
            <path
              id="_466747488"
              d="m97.2265 89.9441c-.2846 0-.5696-.1007-.7963-.3048-.4913-.441-.5317-1.197-.0907-1.6858 1.4313-1.5951 4.7575-5.9594 4.7575-12.0751.0002-.6578.5342-1.1919 1.1944-1.1919.6603-.0001 1.1943.5343 1.1943 1.1919 0 6.9421-3.757 11.8735-5.3698 13.6701-.2368.2621-.5619.3957-.8894.3956zm-21.2045-.0303c-.2948 0-.5972-.1082-.829-.3325-3.4544-3.3338-5.2842-8.071-5.2842-13.7029.0002-.6578.5343-1.1919 1.1945-1.1919 7.6986-.0001 13.0174 2.5653 15.8195 7.625.32.5745.1109 1.3053-.4661 1.6228-.5769.3175-1.3028.1085-1.6228-.4661-2.2351-4.0419-6.3348-6.1333-12.5035-6.3727.2371 4.4248 1.7891 8.1315 4.5231 10.7673.4743.4585.4865 1.2145.0303 1.6883-.2341.2418-.5493.3628-.8618.3628z"
            ></path>
            <path
              id="_466747464"
              d="m97.3756 102.339c-.3124 0-.6254-.1209-.8596-.3653-.4557-.4763-.4436-1.2321.03-1.6883 2.7317-2.6358 4.2813-6.3399 4.5207-10.7698-6.9319.2722-11.2712 2.8902-13.2569 7.9829-.2393.6147-.9298.9223-1.5446.6803-.6144-.2394-.9173-.9298-.6779-1.5446 2.4595-6.3224 8.0812-9.5276 16.7041-9.5276.6603 0 1.1943.5342 1.1943 1.1945 0 5.6292-1.8269 10.3666-5.2815 13.7029-.2316.2216-.5317.335-.8286.335zm-24.7678-4.9868c-.461 0-.9023-.267-1.0964-.7206-1.0632-2.472-1.6026-5.274-1.6026-8.3305.0002-.6603.5343-1.1945 1.1945-1.1945h.0025c3.2511 0 6.1082.4637 8.4919 1.3759.6153.2343.9221.9248.6904 1.5396-.2366.6172-.9273.9247-1.5421.6879-1.8168-.6931-3.9687-1.0936-6.4156-1.1919.1215 2.2552.5821 4.3266 1.3734 6.1711.2625.6047-.0202 1.3078-.6224 1.5674-.1561.0654-.3149.0957-.4739.0957z"
            ></path>
            <path
              id="_466747368"
              d="m76.022 114.762c.3125 0 .6275-.1234.8618-.3678.4565-.4739.4435-1.2272-.0303-1.6857-1.8645-1.7968-3.1774-4.0798-3.9057-6.786-.171-.6376-.824-1.0155-1.464-.8416-.637.1687-1.0104.824-.8416 1.4615.8418 3.127 2.3736 5.7805 4.5508 7.8845.2343.2217.5342.3352.829.3351zm21.3536 0c-.3124 0-.6254-.1234-.8596-.3678-.4557-.4739-.4436-1.2272.03-1.6883 2.7317-2.6333 4.2813-6.3374 4.5207-10.7647-6.9296.2695-11.2712 2.8877-13.2569 7.9828-.1786.4586-.6198.7585-1.1113.7585-.491-.0001-.9348-.2999-1.1112-.7585-1.5569-3.9991-4.5383-6.4433-9.1118-7.4688-.6445-.1437-1.0507-.7837-.9045-1.4237.1438-.6452.7836-1.0482 1.4261-.9047 4.4151.9878 7.6679 3.2179 9.7014 6.6373 2.8502-4.8029 8.0837-7.2344 15.5929-7.2344.6603 0 1.1943.5341 1.1943 1.1918 0 5.6318-1.8269 10.3692-5.2815 13.7029-.2316.2242-.5317.3377-.8291.3377z"
            ></path>
            <path
              id="_466747416"
              d="m86.7116 131.406c-3.2986 0-6.5797-.9473-9.3263-2.7868-4.8178-3.2305-7.4765-8.7237-7.4765-15.4718.0002-.6628.5343-1.1944 1.1945-1.1944 7.512-.0001 12.7427 2.4316 15.5952 7.2319 2.853-4.8004 8.0837-7.2319 15.5929-7.2319.6603-.0001 1.1943.5316 1.1943 1.1944 0 4.2131-1.0029 7.8871-2.986 10.9184-1.7815 2.7315-4.324 4.8331-7.3504 6.0779h.0002c-2.0536.8466-4.2511 1.2625-6.4379 1.2623zm5.982-2.366h.0023zm-20.3602-14.6757c.2897 5.3622 2.5295 9.6915 6.3797 12.2718 3.9164 2.6231 9.0993 3.1195 13.5291 1.3002 5.3018-2.1796 8.4717-7.0908 8.822-13.5719-6.9271.2696-11.2687 2.8878-13.2544 7.9829-.1786.456-.6198.761-1.1113.761-.491-.0001-.9348-.3049-1.1112-.761-1.9855-5.0951-6.3273-7.7133-13.2543-7.9829z"
            ></path>
            <path
              id="_466747344"
              d="m51.2673 127.953c.305 0 .6096-.1158.844-.3502l75.4897-75.492c.4683-.4662.4682-1.2195-.0003-1.6882-.4635-.4637-1.2197-.4637-1.6884 0l-75.4893 75.4919c-.466.4662-.4662 1.2221-.0001 1.6883.2349.2343.5392.3503.8444.3502zm-4.6288-4.6315c-.3072 0-.6126-.1159-.8445-.3502-.4657-.4637-.4661-1.2196 0-1.6857l75.4922-75.4895c.4638-.4686 1.2193-.4686 1.688.0001.4665.4635.4637 1.2196 0 1.6857l-75.4892 75.4894c-.2341.2343-.542.3503-.8469.3503z"
            ></path>
          </g>
          <path
            id="_466746504"
            d="m76.6116 75.2031c3.8193.7846 6.7929 2.461 8.8926 5.0145l.0003-7.7696c-1.2423-3.045-3.3509-5.182-6.3774-6.4395-.0949-.0254-.1848-.062-.2684-.1083-1.8432-.7217-4.0157-1.1289-6.5283-1.2275.2301 4.295 1.6976 7.9095 4.2812 10.5304z"
            fill="#e6a073"
          ></path>
          <path
            id="_466746696"
            d="m84.3747 82.7053c-1.7982-2.7496-4.5855-4.4753-8.4397-5.215-.0845-.0063-.1687-.0219-.2511-.0465-1.0412-.1861-2.1585-.302-3.3535-.3483.2301 4.2927 1.6977 7.9097 4.2816 10.5285.7865.1612 1.5365.36 2.2482.5958z"
            fill="#e6a073"
          ></path>
          <path
            id="_466746408"
            d="m80.7003 64.1007c2.5742 1.206 4.5751 3.0025 5.9907 5.3823 1.5555-2.6621 3.658-4.343 6.0014-5.4042-.5332-4.9241-2.7897-11.486-5.9939-15.3466-3.2085 3.8663-5.4673 10.442-5.9982 15.3685z"
            fill="#e6a073"
          ></path>
          <path
            id="_466746888"
            d="m94.16 66.0323c-2.7354 1.0879-4.8885 3.0151-6.267 6.414v6.7407l14.5015-14.5013c-2.9078.09-5.543.3732-7.8039 1.184-.1286.08-.2741.1364-.4306.1626z"
            fill="#e6a073"
          ></path>
          <path
            id="_466746624"
            d="m119.993 50.4638-33.2405 33.2407c-.0187.02-.038.0393-.0582.0581l-6.6488 6.6489c-.0182.0194-.037.0382-.0563.0562l-6.5131 6.5132c-.0159.0168-.0324.0333-.0493.0493l-24.9549 24.9548 2.9406 2.9407 73.5138-73.5163-2.9419-2.9369z"
            fill="#ffcc80"
          ></path>
          <g fill="#e6a073">
            <path
              id="_466746480"
              d="m76.936 90.144c-1.3878-.3487-2.9273-.5583-4.6056-.6257.0855 1.5873.339 3.0836.7576 4.4737z"
            ></path>
            <path
              id="_466746552"
              d="m79.0199 100.694c2.6828.9175 4.8499 2.3782 6.4845 4.3668l.0001-10.8503-6.4838 6.4838z"
            ></path>
            <path
              id="_466746384"
              d="m87.893 92.6335c2.0624-2.5115 4.9708-4.173 8.7025-4.9723 1.2543-1.4659 3.463-4.5455 4.23-8.7719l-12.9325 12.9324z"
            ></path>
            <path
              id="_466746264"
              d="m97.3216 89.9404c-4.7011.9408-7.8044 3.3694-9.4286 7.3498v7.7668c2.098-2.5551 5.072-4.2308 8.8978-5.0137 2.5795-2.6185 4.0437-6.2317 4.2759-10.5275-1.2437.0489-2.404.1732-3.4822.3738-.0857.0268-.1739.0437-.2629.0508z"
            ></path>
            <path
              id="_466745808"
              d="m85.5044 109.716c-1.5036-3.6916-4.2572-6.0234-8.398-7.1076l-.0008-.0001-3.9678 3.9686c.7199 2.3048 1.8842 4.2821 3.4712 5.8901 3.8248.7832 6.7972 2.4588 8.8953 5.0125z"
            ></path>
            <path
              id="_466746192"
              d="m97.4714 102.335c-4.7839.9178-7.937 3.3559-9.5784 7.3801v7.7637c2.0985-2.5538 5.0709-4.2293 8.895-5.0124 2.5813-2.6164 4.0465-6.2307 4.2787-10.5255-1.1962.0465-2.3154.163-3.3585.3502-.0777.0227-.1571.0373-.2368.0439z"
            ></path>
            <path
              id="_466745832"
              d="m85.5043 122.139c-1.6413-4.0244-4.7943-6.4625-9.5768-7.3804-.0793-.0065-.1585-.0212-.236-.0439-1.0433-.1873-2.1623-.3038-3.3581-.3505.2897 5.3622 2.5295 9.6915 6.3797 12.2718 2.0193 1.3524 4.3753 2.1396 6.7911 2.3354z"
            ></path>
            <path
              id="_466745664"
              d="m97.4685 114.758c-4.7817.9181-7.9343 3.3561-9.5755 7.3796l.0001 6.8337c1.4824-.1192 2.9531-.4626 4.3491-1.0359 5.3018-2.1796 8.4717-7.0908 8.822-13.5719-1.1947.0465-2.3125.1628-3.3546.3497-.0791.0234-.1599.0383-.2411.0448z"
            ></path>
          </g>
          <path
            id="_466745688"
            d="m103.4857 100.7213c0 4.388-1.109 8.233-3.233 11.2942.6598-.0422 1.3393-.0632 2.0387-.0632.6603-.0001 1.1943.5316 1.1943 1.1944 0 4.2131-1.0029 7.8871-2.986 10.9184-1.7815 2.7315-4.324 4.8331-7.3502 6.0779-1.6838.6942-3.4643 1.0988-5.2564 1.2218v5.7102c0 .6602-.5369 1.1945-1.1946 1.1944-.6576 0-1.1943-.5342-1.1943-1.1943v-5.7121c-2.885-.2025-5.7079-1.1298-8.119-2.7445-4.8177-3.2305-7.4764-8.7237-7.4764-15.4718.0002-.6628.5343-1.1944 1.1945-1.1944.7 0 1.3802.021 2.0404.0632-.7555-1.0897-1.3834-2.2804-1.8799-3.5657l-18.2278 18.2284c9.1017 7.6754 20.852 12.3068 33.6625 12.3069 28.8295 0 52.2872-23.4572 52.2867-52.2867 0-12.8109-4.631-24.5584-12.3089-33.66l-23.1939 23.1938c-.0873 4.743-1.9158 8.5193-3.5462 10.9596.7584-.0568 1.5435-.0852 2.3552-.0852.6603-.0001 1.1943.5342 1.1943 1.1944 0 4.3854-1.1087 8.2295-3.232 11.2916.6594-.0421 1.3387-.0631 2.0377-.0631.6603 0 1.1943.5341 1.1943 1.1918z"
            fill="#bbdefb"
          ></path>
          <path
            id="_466745952"
            d="m69.9088 88.3012c.0002-.6602.5343-1.1944 1.1945-1.1944.0026-.0001.0024 0 .0024 0 .6975 0 1.3768.0213 2.0371.0639-2.1236-3.0609-3.234-6.9052-3.234-11.2923.0002-.6578.5343-1.1919 1.1945-1.1919.6999 0 1.38.0212 2.0404.0635-2.1242-3.0629-3.2349-6.9087-3.2349-11.2944.0002-.6603.5343-1.1944 1.1945-1.1944 2.7372 0 5.1715.3225 7.2982.965.7397-5.6461 3.4284-13.085 7.453-17.1096.2243-.2244.529-.3503.844-.3503.3152-.0001.62.126.8417.3503 4.0306 4.0282 6.7208 11.4769 7.4555 17.1249 3.2588-.9292 6.7683-.9777 9.8231-.9802l15.5427-15.5426c-9.102-7.6754-20.8546-12.3043-33.663-12.3043-28.829 0-52.2867 23.4522-52.2867 52.2842.0002 12.8108 4.6315 24.5609 12.3069 33.6626l24.4873-24.4864c-.861-2.2821-1.2972-4.8248-1.2972-7.5736z"
            fill="#bbdefb"
          ></path>
        </g>
      </g>
    </svg>
  );
};

const IconChile = () => {
  return (
    <svg
      id="fi_7315474"
      enable-background="new 0 0 512 512"
      height="26"
      viewBox="0 0 512 512"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="XMLID_1_">
        <g id="XMLID_2846_">
          <path
            id="XMLID_717_"
            d="m226 111.4c-128.9 49.5-27.4 195.5-11.7 238.9 16.4 45.2 9.6 134.4-83.9 126.7 0 0 5.9 29.1 61.5 29.1 86.7 0 173.8-66.3 188.5-201 10.9-100.4-47.8-234.6-154.4-193.7z"
            fill="#db3529"
          ></path>
          <path
            id="XMLID_1293_"
            d="m226 111.4c-3.6 1.4-7 2.9-10.3 4.4 65.5 20.7 99.1 119.2 90.6 197.3-11.5 105.7-67.7 169.2-133.2 191.7 5.5.8 11.7 1.3 18.7 1.3 86.7 0 173.8-66.3 188.5-201 11-100.4-47.7-234.6-154.3-193.7z"
            fill="#b4271e"
          ></path>
          <path
            id="XMLID_716_"
            d="m295 110.6-10.7-10.7-68.8-9.3c-12.8-44-.5-67.6 41.5-67.6v-17c-93 0-54.3 90.7-54.3 90.7l-32.2 42.7-2.8 23.9s29.4 1.1 73.3-18.4c43.9-19.6 54-34.3 54-34.3z"
            fill="#399349"
          ></path>
          <g id="XMLID_715_">
            <path
              id="XMLID_1290_"
              d="m350.1 321.8-9.9-1.6c13.8-85.7-17.5-126.9-17.8-127.4l7.9-6.2c1.3 1.9 34.3 45.3 19.8 135.2z"
              fill="#db3529"
            ></path>
          </g>
          <ellipse
            id="XMLID_1304_"
            cx="226.9"
            cy="256.3"
            fill="#e25d56"
            rx="22.4"
            ry="48.7"
            transform="matrix(.959 -.283 .283 .959 -63.148 74.54)"
          ></ellipse>
          <path
            id="XMLID_1371_"
            d="m295 110.6-10.7-10.7-68.6-9.3 28.4 25.1 9.3 23.3c33.5-16.6 41.6-28.4 41.6-28.4z"
            fill="#2b6e38"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export {
  IconsWifi,
  IconBed,
  IconParking,
  IconAir,
  Icon247,
  IconsTree,
  IconsPets,
  IconsFood,
  IconChile,
  IconsNoGluten,
};
