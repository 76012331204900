import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

import FormField from "components/MeForm/FormField";
import upStore from "store/upStore";
import { classNames } from "tools/ui";

/**
 *
 * @param {*} props -
 * @param {Boolean} selection -
 * @param {String} name -
 * @param {String} label -
 * @param {String} value -
 * @param {Function} onChange -
 * @param {Boolean} required -
 * @param {Map} data_source -
 * @returns Component DropDown
 */

function MultiSelectionField(props) {
  const { field, attrs, data } = props;
  const { name } = field;
  const { level } = attrs;
  // props.data_source must be a Map()

  function selectedItem(selected) {
    // if (props.onChange) {
    //   props.onChange(props.name, data, data.recordid, data);
    // }
    // setSelectedOptions(data);
    data.value = selected;
    upStore(level, {
      fieldName: name,
      value: data.value,
      field: field,
    });
  }

  const options = field.options ?? [];
  console.log(options, 'props.data_source')
  if (props.data_source) {
    console.log(props.data_source, 'antes de value')
    options = Array.from(props.data_source.values());
    console.log(options, 'despues de value')
  }

  return (
    <FormField {...field}>
      <Listbox
        value={data.value}
        onChange={(item) => selectedItem(item)}
        multiple
      >
        {({ open }) => (
          <Fragment>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full h-12 cursor-default rounded-md border border-gray-100 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus:border focus:border-cyan-500 sm:text-sm">
                {data.value.map((option) => option.name).join(", ")}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((value) => (
                    <Listbox.Option
                      key={value.id}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-cyan-500" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-8 pr-4",
                        )
                      }
                      value={value}
                    >
                      {({ selected, active }) => (
                        <Fragment>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate",
                            )}
                          >
                            {value.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-cyan-600",
                                "absolute inset-y-0 left-0 flex items-center pl-1.5",
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </Fragment>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Fragment>
        )}
      </Listbox>
    </FormField>
  );
}

export default MultiSelectionField;
