import React from "react";

import { classNames, colors } from "tools/ui";
import upStore from "store/upStore";
import mainStore from "store/mainStore";
import useMainStore from "store/mainStore";

function BooleanCell(props) {
  let parentStore = useMainStore.getState();
  const { save: saveParent, reload: reloadParent } = parentStore;
  const { store, updateStore } = mainStore();
  const { field, attrs, data, parentRec, record } = props;
  const { name, readOnly, label } = field;
  const level = "child";
  const onChange = async (event) => {
    let _value = event.target.value;
    data.value = !data.value;
    console.log("props - ", props);
    upStore(level, {
      fieldName: name,
      value: _value,
      field: field,
      recId: record.id,
      parentRec,
    });

    if (parentRec) {
      const _store = store;
      const field_ = field.name;
      _store[parentRec.fieldName] = [
        [
          "write",
          [record.id],
          {
            [field_]: data.value,
          },
        ],
      ];
      updateStore({ ..._store });
      await saveParent();
      await reloadParent();
    }
  };

  let _color = props.readOnly ? colors.readOnly : props.color ?? "bg-white";
  let _value = props.value;

  return (
    <input
      id={name}
      name={name}
      className={classNames("h-5 w-5 rounded-full", _color)}
      type="checkbox"
      inputMode="numeric"
      value={data.value}
      checked={data.value ? true : false}
      // onBlur={handleBlur}
      // onBlur={onChange}
      onChange={onChange}
      // readOnly={readOnly}
    />
  );
}

export default BooleanCell;
