import React from "react";

/**
 *
 * @param {*} props -
 * @param {Boolean} readOnly -
 * @param {String} type -
 * @param {String} name -
 * @param {String} placeholder -
 * @param {String} value -
 * @param {Function} onChange -
 * @param {Boolean} translate -
 * @returns Component TextField
 */

function TextAreaCell(props) {
  const { data, field } = props;

  function onChange() {
    console.log("Not implemented update in TextAreaCell!");
  }

  return (
    <textarea
      id={field.name}
      value={data}
      rows={3}
      onChange={onChange}
      className={"border-none focus:border-none border-0 bg-inherit"}
    />
  );
}

export default TextAreaCell;
