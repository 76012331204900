import React from "react";

import IconTable from "components/Icons/IconTable";
import TableRow from "components/MeTable/TableRow";
import factoryCell from "components/Meta/factoryCell";
import { triggerMethod } from "tools/helpers";
import { useWizardStore } from "store/wizardStore";

function BodyTable(props) {
  const {
    parentRec,
    records,
    ctxView,
    onActionButton,
    selectable,
    onClickCell,
    updateRecords,
    reloadRecords,
    fieldName,
    level,
  } = props;
  const { webtree, webfields, model, table_action, access } = ctxView;

  const { record: recordWizard, updateWizard } = useWizardStore();
  const { selectedRows } = recordWizard;

  const cellButtonStart = [];
  const cellButtonEnd = [];
  if (table_action) {
    for (const buttonType of table_action) {
      if (buttonType === "add") {
        continue;
      } else if (buttonType === "edit" || buttonType === "open") {
        cellButtonStart.push(buttonType);
      } else if (buttonType === "delete") {
        const accessDelete = access?.delete
          ? access.delete(parentRec.record)
          : true;
        if (accessDelete) cellButtonEnd.push(buttonType);
      } else {
        cellButtonEnd.push(buttonType);
      }
    }
  }

  const treeView = webtree.map((val) => {
    return { ...webfields[val.name], ...val };
  });

  function actionButton(rec, value) {
    return (
      <td key={value} className="text-center w-11">
        <IconTable name={value} record={rec} onClickButton={onActionButton} />
      </td>
    );
  }

  async function triggerAction(record, method) {
    if (typeof method !== "string") {
      await method(record);
      return;
    }
    await triggerMethod(model, method, record, false);
    reloadRecords();
  }

  function onDoubleClickRow(rec) {
    if (props.onDoubleClick) {
      props.onDoubleClickRow(rec);
    }
  }

  function onClickRow(rec) {
    if (!selectable) {
      return;
    }
    let selectedRows_ = selectedRows ?? new Map();
    if (selectable === "multi") {
      if (selectedRows_.get(rec.id)) {
        selectedRows_.delete(rec.id);
      } else {
        selectedRows_ = selectedRows_.set(rec.id, rec);
      }
    } else {
      selectedRows_ = new Map([[rec.id, rec]]);
    }
    if (props.onClickRow) {
      props.onClickRow(rec);
    }
    updateWizard({ selectedRows: selectedRows_ });
  }

  function onChange(field, value, rec) {
    if (updateRecords) {
      updateRecords(field, value, rec.id);
    }
  }

  function handleCheck() {}

  function getRow(idx, record, treeView, parentRec) {
    return (
      <TableRow
        key={idx}
        record={record}
        selectable={selectable}
        selectedRows={selectedRows}
        readOnly={props.readOnly}
        onClick={onClickRow}
        onDoubleClick={onDoubleClickRow}
      >
        {selectable === "multi" && (
          <td className="text-center w-11">
            <input
              key={record.id}
              type="checkbox"
              name={"selectable"}
              onChange={handleCheck}
              checked={selectable && selectedRows?.get(record.id)}
              className="h-5 w-5 rounded-lg accent-sky-600"
            />
          </td>
        )}
        {cellButtonStart.map((value, index) =>
          actionButton(record, value, index),
        )}
        {treeView.map((field, key) => {
          return factoryCell({
            record,
            field,
            level,
            key,
            model,
            parentRec,
            fieldName,
            triggerAction,
            onClickCell,
            onChange: (f, v, rec) => onChange(f, v, rec),
          });
        })}
        {cellButtonEnd.map((value, index) =>
          actionButton(record, value, index),
        )}
      </TableRow>
    );
  }

  return (
    <tbody>
      {records && !records.size !== 0
        ? Array.isArray(records)
          ? records.map(function (item, i) {
              return getRow(i, item, treeView, parentRec);
            })
          : Array.from(records.values()).map(function (item, i) {
              return getRow(i, item, treeView, parentRec);
            })
        : null}
    </tbody>
  );
}

export default BodyTable;
