import { create } from "zustand";
import proxy from "api/proxy";
import store from "store";

const fieldsCats = [
  "category",
  "category.name",
  "category.name_icon",
  "category.childs",
  "category.childs.name",
  "category.childs.name_icon",
];
const modelCats = "sale.shop-product.category";
const orderCats = [["category.name", "ASC"]];
const fieldsPts = [
  "name",
  "list_price",
  "default_uom",
  "sale_uom",
  "sale_price",
  "sale_price_taxed",
  "products_mix",
  "products_mix.name",
  "products_mix.list_price",
  "products_mix.default_uom",
  "products_mix.sale_uom",
  "products_mix.sale_price",
  "products_mix.sale_price_taxed",
];
const modelPts = "product.product";
const orderPts = [["code", "ASC"]];
const limit = 100;

async function getProducts(categoryId) {
  const domain = [
    ["categories", "=", categoryId],
    ["template.salable", "=", true],
    ["template.active", "=", true],
  ];
  const { data } = await proxy.search(
    modelPts,
    domain,
    fieldsPts,
    limit,
    orderPts,
  );
  let res = new Map();
  if (data) {
    const list = data.map((rec) => [rec.id, rec]);
    res = new Map(list);
  }
  return res;
}

async function getCategories() {
  const sessionUser = store.get("ctxSession");
  const domain = [["shop", "=", sessionUser.shop]];
  const { data, error } = await proxy.search(
    modelCats,
    domain,
    fieldsCats,
    100,
    orderCats,
  );
  let categories = new Map();
  if (!error) {
    for (const ct of data) {
      const cat = ct["category."];
      if (cat["childs."].length > 0) {
        const list = cat["childs."].map((rec) => [rec.id, rec]);
        cat.childs = new Map(list);
      } else {
        delete cat["childs"];
      }
      delete cat["childs."];
      categories.set(cat.id, cat);
    }
  }
  return { categories, error };
}

async function getTables(value) {
  const session = store.get("ctxSession");
  const fields = ["id", "rec_name", "sale"];
  const domain = [
    ["shop", "=", session.shop],
    // ["state", "=", "available"],
  ];
  const orderTables = [["name", "ASC"]];

  const { data, error } = await proxy.search(
    "sale.shop.table",
    domain,
    fields,
    null,
    orderTables,
  );
  return data;
}

export const useStoreRest = create((set, get) => ({
  categories: null,
  products: {},
  unavailables: [],
  category: null,
  currentCats: [],
  tables: null,
  setTables: async () => {
    let tables = get().tables;
    if (tables) {
      return;
    }
    tables = await getTables();
    set({ tables });
  },
  catsIndex: [],
  gotoCategory: async (categoryId) => {
    let catsIndex = get().catsIndex;
    const categories = get().categories;
    if (categoryId) {
      catsIndex.push(categoryId);
    } else {
      catsIndex.pop();
    }
    let current = categories;
    for (const idx of catsIndex) {
      current = current.get(idx);
      if (current.childs) {
        current = current.childs;
      } else {
        current = categories;
      }
    }
    let products = get().products;
    if (categoryId && !products[categoryId]) {
      const productsOfCategory = await getProducts(categoryId);
      products[categoryId] = productsOfCategory;
      set({ products: products });
    }
    set({ catsIndex: catsIndex, currentCats: current });
  },
  setCategories: async (_categories) => {
    const cats = get().categories;
    const _unavailables = get().unavailables;
    const noProducts = await getUnavailables(_unavailables);
    if (noProducts) {
      let catsProducts = get().products;
      for (const pdts of Object.values(catsProducts)) {
        for (const pdtId of noProducts) {
          pdts.delete(pdtId);
        }
      }
    }
    if (cats) return cats;
    const { categories, error } = await getCategories();
    if (!error) {
      set({ categories, currentCats: categories });
    }
  },
  setProducts: async (categoryId) => {
    let products = get().products;
    if (products) {
      const pts = products[categoryId];
      if (pts) {
        return;
      }
    }
    const productsOfCategory = await getProducts(categoryId);
    products[categoryId] = productsOfCategory;
    set({ products: products });
  },
}));

async function getUnavailables(current) {
  let res;
  const dom = [
    ["active", "=", false],
    ["template.salable", "=", true],
    ["template.type", "=", "goods"],
    ["id", "not in", current],
  ];
  const { data } = await proxy.search("product.product", dom, ["id"]);
  if (data.length > 0) {
    res = data.map((rec) => rec.id);
  }
  return res;
}
