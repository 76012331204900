import React, { useState, useEffect, Fragment } from "react";
import Board from "components/Meta/Board";
import StdButton from "components/Buttons/StdButton";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import ModelEvents from "../ModelEvents";
import mainStore from "store/mainStore";
import proxy from "api/proxy";

export const ModalWizzardEvents = ({
  // ctxView,
  // handleStatusShedule,
  onClose,
}) => {
  const ctxEvents = ModelEvents.ctxView();
  const {
    updateStore,
    updateRecord,
    reload,
    store,
    record: activeRecord,
  } = mainStore();
  let [selectFolio, setSelectedFolio] = useState(null);
  const getEvents = async () => {
    const { data: dataEvents } = await proxy.search(
      "sale.sale",
      ["state", "=", "draft"],
      [
        "rec_name",
        "number",
        "description",
        "state",
        "party",
        "party.name",
        "sale_date",
      ],
    );
    dataEvents;
  };

  const addEvents = async (record) => {
    const _activeRecord = { ...activeRecord };
    const _storeRecord = { ...store };
    _activeRecord.party = record["main_guest."];
    _activeRecord.id_event = record.id;
    _storeRecord.party = record["main_guest."];
    updateRecord(_activeRecord);
    updateStore(_storeRecord);
    setSelectedFolio(record);
  };

  ctxEvents.webfields.add.method = addEvents;

  const handlerTransferEvent = async () => {
    let _storeRecord = { ...store };
    _storeRecord.status = "transferred";
    _storeRecord.state = "transferred";
    const { data: resTransfer, error } = await proxy.methodCall({
      model: "sale.sale",
      method: "transfer_to_sale",
      args: [_storeRecord.id, selectFolio.id],
    });
    if (resTransfer) {
      reload();
      onClose();
    }
    console.log(_storeRecord);
    console.log(error, "errores");
  };

  const cancelledFolio = () => {
    let _activeRecord = { ...record };
    let _storeRecord = { ...store };
    _activeRecord.party = null;
    _storeRecord.party = null;
    updateRecord(_activeRecord);
    updateStore(_storeRecord);
    onClose();
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <Fragment>
      {selectFolio ? (
        <div className="px-10">
          <ArrowLeftIcon
            className="w-10 absolute right-10 top-2 cursor-pointer flex text-2xl my-auto fi fi fi-rr-arrow-small-left text-lime-700 bg-lime-200 h-10  rounded-xl py-1 px-2 items-center text-center hover:opacity-90 disabled:opacity-50 justify-center gap-2 active:scale-95"
            onClick={() => setSelectedFolio(null)}
          />
          <div className="mt-10">
            <div className="font-medium text-xl mb-3">Datos de evento</div>
            <div>id: {selectFolio.id}</div>
            <div>Numero del evento: {selectFolio.number}</div>
            <div>
              Nombre evento:{" "}
              {selectFolio["party."].name || selectFolio.description}
            </div>
            {/*<div>Numero ID: {selectFolio["main_guest."].id_number}</div>
            <div>Telefono: {selectFolio["main_guest."].mobile}</div> */}
          </div>
          <div className="flex mx-auto space-x-3 justify-center mt-10">
            <StdButton
              content="Transferir a evento"
              color="bluePresik"
              style="uppercase w-[200px]"
              onClick={() => handlerTransferEvent()}
            />
            <StdButton
              content="Cancelar"
              color="rose"
              style="uppercase w-[200px] "
              onClick={() => cancelledFolio()}
            />
          </div>
        </div>
      ) : (
        // <FullTable
        //   records={events}
        //   isLoading={false}
        //   limit={10}
        //   // updateRecords={updateRecords}
        //   onChangeView={() => console.log("cambio de vista")}
        //   ctxView={ctxEvents}
        // />

        <div className="headerEvents pr-8">
          {" "}
          <Board ctxView={ctxEvents} />{" "}
        </div>
      )}
    </Fragment>
  );
};
