import React from "react";
import date from "date-and-time";

import { tableStyle } from "./constants";
import { classNames } from "tools/ui";

const baseStyle = "text-xs text-center text-gray-100";
const fmtDate = "YYYY-MM-DD";

function CategoryRow({ data, dates, prices }) {
  const categoryCell = dates.map((day, index) => {
    const datefmt = date.format(day, fmtDate);
    let value = "----";
    if (prices && prices[datefmt]) {
      const prices_by_acco = prices[datefmt];
      const price = prices_by_acco[data.accoId];
      value = Math.floor(price[1] / 1000);
    }
    return (
      <td
        key={index}
        className={classNames(
          "calendar-category-row",
          baseStyle,
          categoryStyle,
        )}
      >
        ${value}
      </td>
    );
  });
  const { categoryStyle, border } = tableStyle;

  return (
    <tr key={data.accoId} className={classNames(categoryStyle)}>
      <td>
        <span className="pl-3 text-sm text-zinc-100 font-bold">
          {data.accoName}
        </span>
      </td>
      {categoryCell}
    </tr>
  );
}

export default CategoryRow;
