import React, { Fragment, useState } from "react";

import BasicModal from "components/Modals/BasicModal";
import { BUTTON_COLORS } from "components/Constants/constants";
import { classNames } from "tools/ui";

const style =
  "block mx-auto w-full h-10 text-center border-[1px] justify-center rounded-xl active:scale-95 active:shadow-sm";

function TButtonCustomModal({
  Component,
  label,
  icon,
  color,
  record,
  name,
  desired_action,
  model,
}) {
  const [open, setOpen] = useState(false);

  let value = label;
  if (desired_action === "sum") {
    value = value.reduceRight(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );
  } else if (desired_action === "count") {
    value = value.length;
  }

  const _color = color ?? "blue";
  const [bgColor, txtColor, border] = BUTTON_COLORS[_color];

  function onClose() {
    setOpen(false);
  }

  return (
    <Fragment>
      <button
        onClick={() => setOpen(true)}
        className={classNames(style, bgColor, txtColor, border)}
        aria-label={name}
      >
        <span className={classNames("w-42 ml-auto  my-auto active:scale-95")}>
          {value}
        </span>
        {icon && (
          <i
            className={classNames(
              "mr-auto my-auto ml-6 text-xl",
              icon,
              txtColor,
            )}
          />
        )}
      </button>
      <BasicModal
        title={model + "." + name}
        open={open}
        onClose={onClose}
        titleSize="text-2xl"
        width="w-1/2"
      >
        <Component record={record} onClose={() => onClose()} name={name} />
      </BasicModal>
    </Fragment>
  );
}

export default TButtonCustomModal;
