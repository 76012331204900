import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { TagIcon, IdentificationIcon } from "@heroicons/react/20/solid";

const TicketTurn = ({ dataTurn = [] }) => {
  console.log(dataTurn, "asdasd");

  return (
    <div className="h-full w-[30vw] bg-gray-200 p-4 rounded-md shadow-md">
      <h3 className="text-2xl text-blue-presik font-semibold mb-5">
        <FM id="ticket_system.ticket.currents_turn" />
      </h3>
      <div className="space-y-4">
        {dataTurn &&
          dataTurn.map((item) => {
            const hiddenChars = item.number_id.length - 4;
            const maskedString =
              hiddenChars > 0
                ? "*".repeat(hiddenChars) + item.number_id.slice(-4)
                : item.number_id;

            return (
              <div
                key={item.id}
                className="bg-slate-100 py-2 px-10 rounded-md flex space-x-4  justify-start items-center"
              >
                <div className="text-8xl text-blue-presik  font-semibold flex">
                  <TagIcon className="w-20" />
                  <span>{item.turn}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-2xl font-bold text-blue-presik ">
                    {(item.position && item?.["position."].number) || ""}
                  </span>
                  <span className="text-2xl flex space-x-2">
                    <IdentificationIcon className="w-5" />
                    <span>{maskedString}</span>
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TicketTurn;
