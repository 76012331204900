import React from "react";
import { FormattedMessage as FM } from "react-intl";
import { signal } from "@preact/signals-react";

import ButtomModalBodyTable from "components/Buttons/ButtonModalBodyTable";
import ButtonTable from "components/Buttons/ButtonTable";
import ButtonLink from "components/Buttons/ButtonLink";
import Circle from "components/Tools/Circle";
import Badge from "components/Tools/Badge";
import IconSource from "components/Icons/IconSource";
import TextCell from "components/MeTable/TextCell";
import IntegerCell from "components/MeTable/IntegerCell";
import TableCell from "components/MeTable/TableCell";
import TextAreaCell from "components/MeTable/TextAreaCell";
import SelectionCell from "components/MeTable/SelectionCell";
import BooleanCell from "components/MeTable/BooleanCell";
import NumericCell from "components/MeTable/NumericCell";
import DropdownCell from "components/MeTable/DropdownCell";
import ButtonCustomModal from "components/MeTable/ButtonCustomModal";
import FloatCell from "components/MeTable/FloatCell";
import CallAction from "components/MeTable/CallAction";
import dates from "tools/dates";

const isNotNumber = (val) =>
  Number.isNaN(val) || typeof val === "undefined" || val === null;
const formatter = Intl.NumberFormat("en-US");

function getChar(attrs) {
  const { data, field, model, key, record } = attrs;
  if (field.editable) {
    return <TextCell key={key} {...attrs} />;
  }
  let color;
  let _value = data.value;
  let align = "text-left";
  if (field.widget) {
    align = "text-center";
    if (field.widget === "circle") {
      color = "white";
      if (field.tags) {
        color = field.tags[_value];
      } else if (field.color) {
        color = field.color;
      }
      _value = <Circle color={color} />;
    } else if (field.widget === "badge") {
      color = "white";
      if (field.tags) {
        color = field.tags[_value];
      } else if (field.color) {
        color = field.color;
      }
      _value = <Badge color={color} value={_value} model={model} />;
    } else if (field.widget === "button-custom-modal") {
      _value = (
        <ButtonCustomModal
          key={key}
          {...field}
          label={_value}
          record={record}
          model={model}
        />
      );
    } else if (field.widget === "menuItem") {
      _value = <ButtomModalBodyTable data={_value} />;
    } else if (field.widget === "call-action") {
      _value = <CallAction key={key} value={_value} />;
    } else if (field.widget.type === "sourceImg") {
      if (field.widget.data) {
        _value = <IconSource data={field.widget.data[_value ? _value : 0]} />;
      } else {
        _value = value;
      }
    }
  } else if (field.translate && _value) {
    // className="bg-lime-200 whitespace-nowrap px-2 py-2 text-sm text-gray-900"
    _value = (
      <FM id={`${model}.${_value}`} key={key}>
        {(msg) => <p key={key}>{msg}</p>}
      </FM>
    );
  }
  return [_value, align, color];
}

function getTextArea(attrs) {
  const { key } = attrs;
  return <TextAreaCell key={key} {...attrs} />;
}

function getSelection(attrs) {
  const { key } = attrs;
  return <SelectionCell key={key} {...attrs} />;
}

function getDate({ data }) {
  if (!data.value) return;
  return dates.fmtDate(data.value, true);
}

function getDatetime(attrs) {
  const { data, field, key } = attrs;
  const _value = dates.getTrytonDateTime2Js(
    data.value,
    true,
    field.formatString,
  );
  // _value = dates.fmtDatetimeTable(_value, field.formatHour);
  return _value;
}

function getFloat(attrs) {
  return <FloatCell key={attrs.key} {...attrs} />;
}

function getNumeric(attrs) {
  const { data, field, key, record } = attrs;
  let align;
  let color;
  let dataCell;
  if (field.editable) {
    dataCell = <NumericCell key={key} record={record} {...attrs} />;
  } else {
    align = "text-right";
    dataCell = data.value;
    if (field.format === "percent") {
      dataCell = dataCell * 100;
    }
    if (isNotNumber(dataCell)) {
      dataCell = "";
    } else {
      dataCell = parseFloat(dataCell).toFixed(field.decimalPlaces || 0);
      dataCell = formatter.format(dataCell);
    }
    if (typeof dataCell === "number" && dataCell < 0) {
      color = "text-rose-700";
    }
    if (field.format === "percent") {
      dataCell = `${dataCell} %`;
    }
  }

  return [dataCell, align, color];
}

function getMany2One(attrs) {
  const { field, record, key, data, onChange, level, parentRec } = attrs;
  const align = "text-left";
  let _value = "";
  let target;
  const raw_name = `${field.name}.`;
  if (record[raw_name]) {
    target = record[raw_name];
  } else {
    target = record[field.name];
  }
  if (field.editable) {
    data.value = target;
    _value = (
      <DropdownCell
        key={key}
        data={data}
        level={level}
        field={field}
        record={record}
        parentRec={parentRec}
        onChange={onChange}
      />
    );
  } else if (field.getIcon) {
    _value = field.getIcon(target);
  } else {
    if (target) {
      if (target.rec_name) {
        _value = target.rec_name;
      } else {
        _value = target.name;
      }
    }
  }

  return [_value, align];
}

function getButtonLink(attrs) {
  const { data, field, key } = attrs;
  return (
    <ButtonLink
      key={key}
      value={data.value}
      icon="chat"
      data_source={field.data_source}
    />
  );
}

function getInteger(attrs) {
  const { data, field, key, recId } = attrs;
  let _value = data.value;
  if (field.editable) {
    return <IntegerCell key={recId} recordId={recId} {...attrs} />;
  }
  return _value;
}

function getButtonFile() {
  return <i className="w-6 h-6 bg-white text-rose-600 fi-rr-file" />;
}

function getBoolean(attrs) {
  const { data, key, field } = attrs;
  if (field.editable) {
    return <BooleanCell key={key} {...attrs} />;
  } else {
    return (
      // fix this @vic
      <input
        type="checkbox"
        className="h-5 w-5 rounded-full"
        checked={data.value ? true : false}
        disabled
      />
    );
  }
}

function getButton({
  data,
  field,
  name,
  model,
  record,
  triggerAction,
  ctxView,
}) {
  let label = `${model}.${name}`;
  let _value = "";

  if (record && field.visible && !field.visible(name, record)) {
    return null;
  }

  const buttonProps = {
    value: data.value,
    label: label,
    method: field.method,
    wizard: field.wizard,
    iconLeft: field.iconLeft,
    iconRight: field.iconRight,
    button_method: field.button_method,
    record: record,
    triggerAction: triggerAction,
    color: field.color,
  };
  _value = <ButtonTable {...buttonProps} key={name} />;
  return _value;
}

function factoryCell({
  record,
  field,
  parentRec,
  fieldName,
  key,
  model,
  triggerAction,
  onClickCell,
  onChange,
  ctxView,
  level,
}) {
  const { name, type } = field;
  let align = "text-center";
  let color;
  // let value;
  let _value = record[name];
  if (name.indexOf(".") > -1) {
    const arrField = name.split(".");
    let value_ = record;
    for (let item of arrField) {
      if (!value_) {
        break;
      }
      value_ = value_[item] ?? value_[item + "."];
    }
    _value = value_;
  }
  if (field.function) {
    _value = field.function(record);
  }
  const data = signal(_value);
  const attrs = {
    _value,
    field,
    model,
    key,
    record,
    data,
    level,
    fieldName,
    parentRec,
    onChange,
  };
  let cellValue;
  switch (type) {
    case "char":
      [cellValue, align, color] = getChar(attrs);
      break;
    case "text":
      cellValue = getTextArea(attrs);
      break;
    case "numeric":
      [cellValue, align, color] = getNumeric(attrs);
      break;
    case "float":
      cellValue = getFloat(attrs);
      break;
    case "integer":
      cellValue = getInteger(attrs);
      break;
    case "selection":
      cellValue = getSelection(attrs);
      break;
    case "date":
      cellValue = getDate(attrs);
      break;
    case "datetime":
      cellValue = getDatetime(attrs);
      break;
    case "many2one":
      [cellValue, align] = getMany2One(attrs);
      break;
    case "input_file":
      cellValue = getButtonFile();
      break;
    case "boolean":
      cellValue = getBoolean(attrs);
      break;
    case "button":
      cellValue = getButton({
        data,
        field,
        name,
        model,
        record,
        triggerAction,
        ctxView,
      });
      break;
    case "link":
      cellValue = getButtonLink(_value, field, key);
      break;
  }

  return (
    <TableCell
      key={name}
      selectable={field.selectable}
      record={record}
      field={field.name}
      align={align}
      onClick={onClickCell}
      color={color}
    >
      {cellValue}
    </TableCell>
  );
}

export default factoryCell;
