import React, { Fragment, useEffect } from "react";
import { FormattedMessage as FM } from "react-intl";
import cn from "classnames";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Transition } from "@headlessui/react";

export const Alert = ({
  text = "app.booking.alert_msg_default",
  color = "bg-red-500",
  position = "fixed",
  onClose,
  show,
}) => {
  useEffect(() => {
    let timer;
    if (show) {
      timer = setTimeout(() => {
        onClose();
      }, 10000);
    }
    return () => clearTimeout(timer);
  }, [show, onClose]);

  return (
    <Fragment>
      <Transition
        show={show}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className={cn("p-5 pr-20 rounded-md bottom-20", position, color)}>
          <span>
            <FM id={text} />
          </span>
          <XMarkIcon
            className="w-10 h-10 absolute right-3 top-3 cursor-pointer hover:text-black bg-red-950 rounded-full p-1"
            onClick={onClose}
          />
        </div>
      </Transition>
    </Fragment>
  );
};
