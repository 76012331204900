import React from "react";
import { FormattedMessage as FM } from "react-intl";

const Header = ({ data = false }) => {
  return (
    <header
      className={` ${
        data.value?.colorPrimary ? data.value?.colorPrimary : "bg-blue-presik"
      }   w-screen relative top-0 z-10 py-3`}
    >
      <div className="grid md:grid-cols-2 justify-between px-14 mx-auto">
        <div>
          <img
            src={data.value?.["company."].logo_link || ""}
            className={`h-16 ${data.value?.grayScale ? "grayscale" : ""}`}
          />
          {/* <img src={data.value?.logo_link} className="h-20 grayscale" /> */}
        </div>
        <div className="md:flex justify-end items-center hidden  align-middle divide-x divide-gray-500 space-x-3">
          {/* <button className="bg-yellow-500 uppercase w-[130px] relative border-0 h-12 from-amber-300 to-amber-400 text-amber-900 flex  hover:shadow-sm bg-gradient-to-r  py-1 px-3 items-center text-center hover:opacity-90 disabled:opacity-50 justify-center gap-2 rounded-xl active:scale-95 active:shadow-sm  ">
            <ArrowLeftIcon className="w-5" /> Home
          </button>{" "} */}
          {data.value?.privacyPolicies && (
            <a
              className="text-white hover:text-yellow-500 transition-all duration-150"
              href={data.value?.privacyPolicies}
              target="blank_"
            >
              <FM id="app.booking.term_cond" />
            </a>
          )}
          {data.value?.dataProtection && (
            <a
              className="text-white hover:text-yellow-500 transition-all duration-150 pl-3"
              href={data.value?.dataProtection}
            >
              <FM id="app.booking.privacy" />
            </a>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
