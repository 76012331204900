import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IntlProvider } from "react-intl";
import store from "store";

import Home from "pages/Home/Home";
import Login from "pages/Login/Login";
import LoginCustomer from "pages/Login/LoginCustomer";
import Dash from "pages/Dash/Dash";
import Apps from "pages/Apps/Apps";
import NotFound from "pages/NotFound";
import Profile from "pages/Dash/Profile";
import intl from "./i18n/messages";
import version from "version";
import getRoutes from "models/routes";
import proxy from "api/proxy";
import env from "./env";

console.log("App version ==> ", version);

const queryClient = new QueryClient();

function MainApp(props) {
  let [messages, localeLang] = intl.getBaseLang();
  const locale = localeLang;
  const sessionStart = store.get("ctxSession");

  const [sessionAuth, setSessionAuth] = useState(sessionStart);
  const [sessionNotAuth, setSessionNotAuth] = useState(null);
  const [routesDash, setRoutesDash] = useState(null);
  const [menusDash, setMenusDash] = useState(null);
  const [routesApps, setRoutesApps] = useState(null);

  function handleSession(session) {
    setSessionAuth(session);
    setDashRoutes(session);
  }

  async function setDashRoutes(session) {
    const { menus, routesDash } = await getRoutes(session);
    setMenusDash(menus);
    setRoutesDash(routesDash);
  }

  async function getSessionApp() {
    const [, side, db] = location.pathname.split("/");
    if (side !== "app") {
      return;
    }
    const { data } = await proxy.search(
      "res.user",
      [["login", "=", "user.app"]],
      ["name", "company", "company.currency"],
    );
    const [user] = data;
    const session = {
      db: db,
      user: user.id,
      user_name: "user.app",
      company: 1,
      currency: user["company."].currency,
      session: env.api_key,
    };
    await store.set("ctxVirtualSession", session);
    return session;
  }

  useEffect(() => {
    async function setSession() {
      const sessionApp = await getSessionApp();
      if (sessionApp) {
        setSessionNotAuth(sessionApp);
        const { routesApps } = await getRoutes(sessionApp);
        setRoutesApps(routesApps);
      }
      if (sessionAuth) {
        setDashRoutes(sessionAuth);
      }
    }
    setSession();
  }, [props]);

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale={locale} messages={messages} defaultLocale="en">
        <BrowserRouter>
          <div className="h-full w-full">
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route
                path="/login"
                element={<Login handleSession={handleSession} />}
              />
              <Route
                path="/loginCustomer"
                element={<LoginCustomer handleSession={handleSession} />}
              />
              <Route path="/dash/*" element={<Dash menusDash={menusDash} />}>
                {routesDash}
                <Route path="profile" element={<Profile />} />
              </Route>
              <Route
                path="/app/:db/*"
                element={<Apps session={sessionNotAuth} />}
              >
                {routesApps}
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          {/* <ReactQueryDevtools /> */}
        </BrowserRouter>
      </IntlProvider>
    </QueryClientProvider>
  );
}

export default MainApp;
