import React from "react";

const Banner = ({ children, colors }) => {
  return (
    <div
      className={`${
        colors ? colors.colorPrimary : "bg-blue-presik"
      } w-screen relative  md:-top-16  -mb-16  `}
    >
      <div className="container mx-auto ">{children}</div>
    </div>
  );
};

export default Banner;
