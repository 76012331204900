import React from "react";

function ButtonLink(props) {
  let ctxlink = props.value;
  if (props.data_source) {
    ctxlink = props.data_source.replace("$value", props.value);
  }

  return (
    <td>
      <p className="pr-3"> {props.value} </p>
      <a href={ctxlink} target="_blank" rel="noopener noreferrer"></a>
    </td>
  );
}

export default ButtonLink;
