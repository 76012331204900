import React, { Fragment, useEffect } from "react";

import proxy from "api/proxy";
import StdButton from "components/Buttons/StdButton";
import useMainStore from "store/mainStore";
import store from "store";
import { useWizardStore } from "store/wizardStore";
import FullTable from "components/MeTable/FullTable";
import Divider from "components/MeForm/Divider";
import funcs from "tools/functions";

const session = store.get("ctxSession");
const ctxViewDctos = {
  selectable: "multi", // options: multi - one - false
  activeSearch: true,
  domain: [
    ["shop", "=", session?.shop],
    ["type", "=", "percentage"],
  ],
  form_action: ["add", "edit"],
  table_action: [],
  model: "sale.shop.discounts",
  webfields: {
    name: { type: "char", readOnly: true },
    discount: { type: "numeric", decimalPlaces: 2, editable: true },
  },
  webform: [{ name: "name" }, { name: "discount" }],
  webtree: [
    { name: "name", width: "40%" },
    { name: "discount", width: "10%" },
  ],
};

const ctxViewPrds = {
  selectable: "multi", // options: multi - one - false
  domain: [],
  form_action: [],
  table_action: [],
  model: "sale.line",
  webfields: {
    product: {
      type: "many2one",
      model: "product.product",
      readOnly: true,
    },
    unit: {
      type: "many2one",
      model: "product.uom",
      readOnly: true,
    },
    quantity: {
      type: "numeric",
      readOnly: true,
    },
    base_price: { type: "numeric", readOnly: true },
    discount_rate: { type: "numeric", readOnly: true },
    discount_amount: { type: "numeric", readOnly: true },
    unit_price: { type: "numeric", readOnly: true },
  },
  webtree: [
    { name: "product", width: "40%" },
    { name: "unit", width: "10%" },
    { name: "quantity", width: "10%" },
    { name: "unit_price", width: "10%" },
    { name: "amount_w_tax", width: "23%" },
  ],
  webform: [
    { name: "product" },
    { name: "unit" },
    { name: "quantity" },
    { name: "unit_price" },
    { name: "unit_price_w_tax" },
    { name: "amount_w_tax" },
  ],
};

function ProductDiscountFlex({ onClose }) {
  const session = store.get("ctxSession");
  const { record, reload } = useMainStore();
  const { record: recordWizard, updateWizard } = useWizardStore();
  // const { upActiveWizard, upStoreWizard } = useWizardStore();
  const shopId = session?.shop;

  async function updateRecordWizard() {
    let discounts = new Map();
    if (shopId) {
      const { data: shops, error } = await proxy.search(
        "sale.shop",
        [
          ["id", "=", shopId],
          ["discounts.active", "=", true],
        ],
        ["discounts.name", "discounts.type_discount", "discounts.discount"],
      );
      if (shops) {
        discounts = funcs.recsToMap(shops[0]["discounts."]);
      }
    }

    const fieldsNames = funcs.getViewFields(ctxViewPrds, "list");
    const { data: sale_lines } = await proxy.search(
      "sale.line",
      [["sale", "=", record.id]],
      fieldsNames,
    );
    updateWizard({ discounts, sale_lines });
  }

  useEffect(() => {
    updateRecordWizard();
  }, []);

  const onDeleteDiscounts = async () => {
    if (recordWizard.selectedRows) {
      const new_dctos = Array.from(recordWizard.discounts.values()).filter(
        (item) => !recordWizard.selectedRows.has(item.id),
      );
      updateWizard({
        discounts: funcs.recsToMap(new_dctos),
        selectedRows: new Map(),
      });
    }
  };

  const onDeleteSaleLines = async () => {
    if (recordWizard.selectedRows) {
      const new_lines = recordWizard.sale_lines.filter(
        (item) => !recordWizard.selectedRows.has(item.id),
      );
      updateWizard({ sale_lines: new_lines, selectedRows: new Map() });
    }
  };

  const onAcept = async () => {
    const lines = recordWizard.sale_lines;
    const discounts = recordWizard.discounts;
    if (lines && discounts) {
      const args = {
        lines: lines.map((item) => item.id),
        discounts: Array.from(discounts.values()),
      };
      await proxy.methodCall({
        model: "sale.line",
        method: "set_discounts_flex",
        args: [args],
      });
    }
    reload();
    onClose(true, false);
    // return;
  };

  const btnsDiscount = (
    <div className="flex flex-row space-x-2">
      <StdButton
        color={"rose"}
        onClick={onDeleteDiscounts}
        name={"done"}
        height={"h-10"}
        size={"w-10"}
        iconLeft={"fi fi-rr-trash"}
      />
    </div>
  );
  const btnsSaleLines = (
    <div className="flex flex-row space-x-2">
      <StdButton
        color={"rose"}
        onClick={onDeleteSaleLines}
        name={"done"}
        height={"h-10"}
        size={"w-10"}
        iconLeft={"fi fi-rr-trash"}
      />
    </div>
  );

  return (
    <Fragment>
      {record.id <= 0 ? (
        <h3 className="text-lg text-slate-500 font-bold">
          Debe guardar la venta antes de agregar descuentos.
        </h3>
      ) : (
        <div>
          <Divider
            field={{ text: "sale.shop.discounts" }}
            rightButtons={btnsDiscount}
          />
          <FullTable
            ctxView={ctxViewDctos}
            records={recordWizard.discounts}
            level={"wizard"}
            parentRec={{ fieldName: "discounts" }}
          />
          <div className="h-10" />
          <Divider field={{ text: "sale.line" }} rightButtons={btnsSaleLines} />
          <FullTable
            ctxView={ctxViewPrds}
            level={"wizard"}
            records={recordWizard.sale_lines}
          />
          <div className="flex justify-end space-x-3">
            <StdButton
              color={"bluePresik"}
              onClick={onAcept}
              name={"done"}
              content={"modal_form.button_accept"}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default ProductDiscountFlex;
