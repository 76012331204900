import React from "react";
import {
  PencilSquareIcon,
  TrashIcon,
  ExclamationTriangleIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";

import { classNames } from "tools/ui";

const ICONS = {
  edit: [PencilSquareIcon, "text-cyan-500 active:text-cyan-700"],
  open: [PencilSquareIcon, "text-cyan-500 active:text-cyan-700"],
  delete: [TrashIcon, "text-rose-500 active:text-rose-700"],
  info: [EllipsisVerticalIcon, "text-gray-700 active:text-gray-700"],
  warning: [ExclamationTriangleIcon, "text-amber-600 text-amber-800"],
};

function IconTable({ name, record, onClickButton }) {
  function onClick(e) {
    e.stopPropagation();
    onClickButton(e, name, record);
  }

  const [Icon, color] = ICONS[name];
  return (
    <div className="flex my-auto">
      <button className="mx-auto active:scale-90" key={name} onClick={onClick}>
        <Icon className={classNames("h-6 w-6", color)} aria-hidden="true" />
      </button>
    </div>
  );
}

export default IconTable;
