import React from "react";
import ModelSchedule from "./ModelSchedule.";
import ModelActivity from "./ModelActivity";
import ModelSale from "./ModelSale";
import ModelFolioWizard from "./ModelFolioWizard";
import ScreenActivity from "./ScreenActivity";
import funcs from "tools/functions";
import SelectDevice from "apps/AccountStatement/SelectDevice";
import store from "store";

const Activity = (props) => {
  const terminal = funcs.getCookie();
  const { config } = props;
  const ctxView = ModelSale.ctxView(config);
  const ctxViewActi = ModelActivity.ctxView(config);
  const ctxViewSchel = ModelSchedule.ctxView(config);
  const ctxViewFolio = ModelFolioWizard.ctxView(config);
  const session = store.get("ctxSession");
  const sellerPermits = session.groups.some(
    (group) => group.name === "Venta en Reserva",
  );

  return (
    <div id="board" className="mx-4 my-3 w-full ">
      <ScreenActivity
        ctxView={ctxView}
        ctxViewAc={ctxViewActi}
        ctxViewSchel={ctxViewSchel}
        ctxViewFolio={ctxViewFolio}
        aside={true}
        position="right"
        permits={sellerPermits}
      />
      {!terminal.device_id && <SelectDevice />}
    </div>
  );
};

export default Activity;
