import React, { Fragment } from "react";
import { signal } from "@preact/signals-react";

import proxy from "api/proxy";
import StdButton from "components/Buttons/StdButton";
import { FormattedMessage as FM } from "react-intl";
import { TrashIcon } from "@heroicons/react/20/solid";
import { classNames } from "tools/ui";
import CharField from "components/MeForm/CharField";
import { useWizardStore } from "store/wizardStore";

const totalPrice = signal(0);
const openDiscount = signal(false);
const coupon = signal("");
const discount = signal(null);
const msgDiscount = signal(null);

const CartBooking = ({
  selectClassification,
  onRemoveItem,
  nights = 0,
  handlePaymentView,
  colors,
}) => {
  const { updateWizard } = useWizardStore.getState();
  const bgColor = colors?.colorPrimary || "bg-blue-presik";

  // const [totalPrice, setTotalPrice] = useState(0);
  const handleRemoveItem = (item) => {
    discount.value = null;
    onRemoveItem(item);
  };

  const searchCoupon = async () => {
    const { data } = await proxy.search(
      "sale.discount",
      [["name", "=", coupon.value]],
      ["name", "type_discount", "discount", "active"],
    );
    if (data.length != 0 && data[0].active == true) {
      discount.value = data[0];
      openDiscount.value = false;
      coupon.value = null;
      updateWizard({ couponDiscount: data[0] });
    } else {
      msgDiscount.value = "No existe descuentos con este cupon";
    }
  };

  const calculateTotalPrice = () => {
    let total = selectClassification.reduce((accumulator, item) => {
      return accumulator + item.sale_price_taxed * nights;
    }, 0);
    if (discount.value && discount.value.type_discount === "percentage") {
      const discountAmount = total * (discount.value.discount / 100);
      total -= discountAmount;
    }
    totalPrice.value = total.toLocaleString("es-ES");
  };
  const ContentCupon = () => {
    return (
      <Fragment>
        <div className="py-5  space-x-3 flex">
          <CharField
            field={{
              name: "coupon",
              // label: "app.booking.coupon",
              required: true,
              translate: true,
              placeholder: "X1X1X1",
              // withChange: searchCoupon,
            }}
            data={coupon}
            attrs={{
              level: "wizard",
            }}
          />

          <StdButton
            style={"relative !rounded-md relative top-1 !h-10"}
            content="app.booking.btn_add_coupon"
            size="w-full"
            color={colors?.colorSecundary ? colors.colorSecundary : "amber"}
            onClick={() => {
              searchCoupon();
            }}
          />
        </div>
        {msgDiscount.value && (
          <div className="relative -mt-5">{msgDiscount.value}</div>
        )}
      </Fragment>
    );
  };

  calculateTotalPrice();

  return (
    <Fragment>
      <div className=" bg-gray-100   rounded-md shadow-md   overflow-hidden   ">
        <h2 className="px-5 mt-5 mb-4 font-semibold text-blue-presik uppercase text-lg text-center">
          <FM id="app.booking.label_aside" />
        </h2>
        <ul className="divide-y divide-gray-300 ">
          {selectClassification.map((item, index) => (
            <li key={index} className="bg-gray-200 p-3  relative">
              <div
                className="flex justify-between pr-12  items-start"
                title={item.product.name}
              >
                <div className="w-[60%]">
                  <h3 className="text-blue-presik font-semibold  line-clamp-2 text-[13px]">
                    {item.product.name}
                  </h3>
                  <span className="text-[12px] flex space-x-1 ">
                    <span className=" text-black">{nights} noches</span>
                    <span className="text-green-600 font-semibold">x</span>
                    <span className=" text-blue-presik">
                      {(item.sale_price_taxed * nights).toLocaleString("es-ES")}
                    </span>
                  </span>
                </div>

                <div className="flex flex-col  text-left items-end w-[40%] ">
                  <span className=" font-bold text-[14px] ">
                    ${item.sale_price_taxed.toLocaleString("es-ES")}
                  </span>
                </div>
                {/* Agrega aquí otros campos del objeto */}
              </div>
              <TrashIcon
                className="w-6 p-1.5 bg-red-300 rounded-full absolute right-3 top-5 text-red-800 cursor-pointer hover:bg-blue-presik hover:text-white transition duration-300  transform"
                onClick={() => handleRemoveItem(item)}
              />
            </li>
          ))}
        </ul>
        <div className={classNames("text-white px-5 space-x-2  ", bgColor)}>
          {totalPrice != 0 && <ContentCupon />}
        </div>
        {discount.value != null && totalPrice != "0" && (
          <span
            className="absolute right-5 bottom-2 text-xs cursor-pointer"
            onClick={() => (openDiscount.value = true)}
          >
            {discount.value.discount}% de descuento aplicado
          </span>
        )}
        <div>
          <div
            className={classNames(
              "text-white flex justify-end items-center  space-x-2   px-5 pt-5 pb-6 text-right relative",
              bgColor,
            )}
          >
            <span className="font-medium text-lg">
              <FM id="app.booking.amount" />
            </span>{" "}
            <span className="relative mt-1">
              <span className=" absolute -top-4 text-[13px]">COP</span>
              <span className="text-yellow-500 font-bold relative">
                ${totalPrice.toLocaleString("es-ES")}
              </span>
            </span>
          </div>
          <div className="">
            <StdButton
              style={`relative !rounded-none ${colors?.callToActions || ""}`}
              content="app.booking.btn_confirm"
              size="w-full"
              color={colors?.colorSecundary ? colors?.colorSecundary : "amber"}
              onClick={() => {
                handlePaymentView();
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CartBooking;
