import en from "./en";
import es from "./es";

let locale =
  navigator.language ||
  (navigator.languages && navigator.languages[0]) ||
  navigator.consumerLanguage ||
  "en";

// console.log("----------------------------------");
// console.log(navigator.languages);
// console.log(navigator.language);
// console.log(navigator.consumerLanguage);

const esCtx = [
  "es",
  "es-ES",
  "es-es",
  "es-ES",
  "es-US",
  "es-419",
  "es-co",
  "es-CO",
  "es-AR",
  "es-pr",
  "es-PR",
  "es-pe",
  "es-PE",
];

const getBaseLang = function () {
  let localeLang, langBase;
  if (esCtx.includes(locale)) {
    langBase = es;
    localeLang = "es";
  } else {
    langBase = en;
    localeLang = "en";
  }
  return [langBase, localeLang];
};

// Read all languages and create base language
const messages = function () {
  let langBase;
  if (esCtx.includes(locale)) {
    langBase = es;
  } else {
    langBase = en;
  }

  return langBase;
};

const msgs = messages();

export default { msgs, getBaseLang };
