import React from "react";
import { signal } from "@preact/signals-react";

import { CheckCircleIcon } from "@heroicons/react/20/solid";
import StdButton from "components/Buttons/StdButton";
import proxy from "api/proxy";
import { FormattedMessage as FM } from "react-intl";

const isLoading = signal(false);
const idJournal = signal(null);

const Invoice = ({ data, colors }) => {
  const getJournal = async () => {
    const { data: dataJournal } = await proxy.search(
      "account.payment.journal",
      [["process_method", "=", "openpay"]],
      ["name"],
    );
    if (dataJournal.length != 0) {
      idJournal.value = dataJournal[0].id;
      console.log(dataJournal, " -- journal");
    }
  };

  const sendPayment = async () => {
    isLoading.value = true;
    const saleArgs = {
      model: "electronic.payment",
      method: "openpay_charge",
      args: [
        {
          journal: idJournal,
          amount: data?.total.toString(),
          iva: 0,
          description: data?.rec_name,
          party: data?.id_party,
          customer_name: data?.name,
          customer_last_name: data?.lastname,
          customer_phone: data?.mobile,
          customer_email: data?.email,
          origin: `hotel.booking,${data?.id}`,
        },
      ],
    };
    const { data: result, error } = await proxy.methodCall(saleArgs);

    if (result?.type === "redirect") {
      window.location.replace(result.url);
      isLoading.value = false;
    } else {
      console.log(error);
    }
  };

  getJournal();

  return (
    <div
      className={`${colors?.bgColor} w-full flex  flex-col justify-center items-center h-[500px] rounded-md text-white space-y-3`}
    >
      <CheckCircleIcon className="w-20" />
      <h2 className={`${colors.textColorPrimary} font-semibold text-xl`}>
        {data.rec_name}
      </h2>
      <span className="uppercase">
        <span className={colors.textColorPrimary}>¡</span>
        <FM id="app.booking.create_reserve" />{" "}
        <span className={colors.textColorPrimary}>
          <FM id="app.booking.successfully" />!
        </span>
      </span>
      <span className="text-xs text-gray-400 pb-4">
        <FM id="app.booking.validate_email" />
      </span>
      {idJournal && (
        <StdButton
          key="add"
          color={
            colors?.bgColorSecundary ? colors.bgColorSecundary : "yellowPresik"
          }
          disabled={false}
          loading={isLoading.value}
          onClick={sendPayment}
          size="w-[200px]"
          content="app.booking.add_pay"
          style="mx-auto px-10 "
        />
      )}

      {isLoading.value && (
        <span>
          <FM id="app.booking.redirect" /> ...{" "}
        </span>
      )}
    </div>
  );
};

export default Invoice;
