import React from "react";

import StdButton from "./StdButton";

function ButtonTable(props) {
  function onClick(event) {
    event.stopPropagation();
    event.preventDefault();
    const method_action = props.button_method ?? props.method;
    props.triggerAction(props.record, method_action);
  }

  const color = props.color || "grey";
  return (
    <StdButton
      key={props.value}
      size="w-full"
      height="h-8"
      iconRight={props.iconRight}
      iconLeft={props.iconLeft}
      onClick={(event) => onClick(event)}
      color={color}
      content={props.label}
    />
  );
}

export default ButtonTable;
