import React, { Fragment, useEffect } from "react";
import FormWizard from "components/MeForm/FormWizard";
import { useWizardStore } from "store/wizardStore";
import mainStore from "store/mainStore";
import proxy from "api/proxy";
import dates from "tools/dates";
import ModelWizardPayment from "./ModelWizardPayment";
import ModelSale from "./ModelSale";
import func from "../../tools/functions";
import { useBtnLoading } from "store/btnLoading";

const WizardAddPayment = ({ onClose }) => {
  const { store: storeWizard, reset, updateWizard } = useWizardStore();
  const { setRecord, record, store } = mainStore();

  const { setButtonLoading } = useBtnLoading();
  const ctxViewSale = ModelSale.ctxView();
  useEffect(() => {
    let _residual = record.total_amount - record.paid_amount;
    const rec = { amount: _residual };
    updateWizard(rec);
    // upActiveWizard(rec);
  }, []);

  const handleAddPayment = async () => {
    const _storeRecord = { ...store };
    if (_storeRecord.invoice_type == "M") {
      _storeRecord.invoice_method = "manual";
      _storeRecord.state = "processing";
    }
    const _data = func.recToTryton(_storeRecord);
    if (!record.party) {
      onClose(false, "missing_party");
      return;
    }

    let recordId = record.id;

    await proxy.saveQuery({
      model: "sale.sale",
      storeRec: _data,
    });

    if (record.invoice_type == "M") {
      onClose();
      setRecord(recordId, "sale.sale", ctxViewSale);
      reset();
      setButtonLoading(false);
      return false;
    }
    const toStore = {
      statement: storeWizard.statement,
      amount: storeWizard.amount,
      number: storeWizard.voucher,
      date: dates.dateToday(),
      description: record.number,
      party: record.party.id,
      account: record.party.account_receivable,
      source: `sale.sale,${recordId}`,
      sale: recordId,
    };
    const { data, error } = await createStatementLine(toStore);
    if (data) {
      const _storeRecord = { ...store };
      _storeRecord.sale_id = recordId;
      const saleDoneError = await callSaleDoneMethod(recordId);
      onClose(true, saleDoneError);
      setRecord(recordId, "sale.sale", ctxViewSale);
      reset();
      setButtonLoading(false);
    } else {
      console.log(error);
    }

    return true;
  };

  const createStatementLine = async (toStore) => {
    const model = "account.statement.line";
    const fields_names = ["id"];
    return await proxy.create(model, toStore, fields_names);
  };

  const callSaleDoneMethod = async (recordId) => {
    const sale = { sale_id: recordId };
    const argSaleDone = {
      model: "sale.sale",
      method: "faster_process",
      args: [sale],
    };
    const { data } = await proxy.methodCall(argSaleDone);
    console.log(data);
    const argSalePost = {
      model: "sale.sale",
      method: "faster_post_invoice",
      args: [sale],
    };

    const { dataPost } = await proxy.methodCall(argSalePost);
    console.log(dataPost);
    const argSaleReconcile = {
      model: "sale.sale",
      method: "reconcile_invoice",
      args: [sale],
    };

    const { dataReconcile, errorReconcile } = await proxy.methodCall(
      argSaleReconcile,
    );
    console.log(errorReconcile);
    return dataReconcile;
  };

  const ctxView = ModelWizardPayment.ctxView();

  return (
    <Fragment>
      <FormWizard
        key="hotel-booking-wizard-payment"
        ctxView={ctxView}
        parentRec={record}
        handleButton={handleAddPayment}
      />
    </Fragment>
  );
};

export default WizardAddPayment;
