import React, { useState } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

import Popup from "apps/HotelPlanner/calendar/Popup";
import PopupHeader from "apps/HotelPlanner/calendar/PopupHeader";
import PopupContent from "apps/HotelPlanner/calendar/PopupContent";
const dataFake = {
  id: 213,
  from_date: "2023-05-24",
  to_date: "2023-05-26",
  status: "pending",
  guests: [],
  charges: [],
  unit_price_w_tax: 47600,
  kind: "accommodation",
  arrival_date: "2023-05-24T05:00:00.000Z",
  departure_date: "2023-05-26T05:00:00.000Z",
  room_id: 29,
  room: "HAB 212",
  accomodation: "[5] SUITE ALEJANDRIA",
  media: "fax",
  booking: "RS-000131",
  guest_name: "ARIANA MENDEZ",
  party: "ARIANA MENDEZ",
  contact: "VICTOR ALFONSO RUIZ REYES",
  channel: "",
  channel_code: "house",
  nationality: "",
  duration: 2,
};
const ButtomModalBodyTable = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const handleClick = () => {};
  const handleMouse = (value) => {
    setOpenPopup(value);
  };
  return (
    <div>
      <EllipsisVerticalIcon
        className="w-6 focus:outline-none focus:border-none cursor-pointer text-right"
        onClick={() => handleClick}
        onMouseEnter={() => handleMouse(true)}
        onMouseLeave={() => handleMouse(false)}
      />
      <Popup
        open={openPopup}
        header={<PopupHeader record={dataFake} />}
        handleMouseOut={handleMouse}
      >
        <PopupContent operation={dataFake} />
      </Popup>
    </div>
  );
};
export default ButtomModalBodyTable;
