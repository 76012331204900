import React, { Fragment, useState } from "react";
import { signal } from "@preact/signals-react";
import {
  ArrowLeftIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

import QuickForm from "components/Meta/QuickForm";
import mainStore from "store/mainStore";
import ViewFiduprevisora from "./FiduprevisoraModel";
import LogoCompany from "pages/Apps/LogoCompany";
import StdButton from "components/Buttons/StdButton";
import TextField from "components/MeForm/TextField";
import CharField from "components/MeForm/CharField";
import "./CrmFiduprevisora.css";
import proxy from "api/proxy";

var id = -1;

const id_number = signal('')
const party = signal(null)
const loading = signal(false)
const msg = signal('')

const fields = {
  partyIdNumber: {
    type: "char",
    name: "party_id_number",
    label: "crm.customer_service.party_id_number",
    placeholder: "Ejemplo, 2222222222",
  }

}

// const CrmFiduprevisora = () => {

// }
function CrmFiduprevisora() {
  const { updateRecord, updateStore } = mainStore();

  // const [idNumber, setIdNumber] = useState(null);
  // const [party, setParty] = useState(null);
  // let [msg, setMsg] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [btnDisabled, setBtnDisabled] = useState(true);

  const handleSend = () => {
    window.open("https://www.utredintegradafoscal-cub.com/", "_self");
  };

  const handleCancel = () => {
    party.value = null;
  };

  const handleField = (field, value) => {
    setIdNumber(value);
  };

  async function handleSearch() {
    loading.value = true;
    if (id_number.value) {
      const domain = [["id_number", "=", id_number.value]];
      const fields = [
        "phone",
        "city_attention.name",
        "id",
        "name",
        "id_number",
        "email",
        "addresses.street",
      ];
      const { data } = await proxy.search("party.party", domain, fields, 10);
      if (data.length === 1) {
        const _party = data[0];
        const _activeRecord = {
          id: id,
          party: _party,
          city_region: party?.["city_attention."],
          phone: _party?.phone,
          email: _party?.email,
          support: "pendiente",
          address:
            _party?.["addresses."].length > 0
              ? _party["addresses."][0].street
              : null,
        };
        updateRecord(_activeRecord);
        updateStore(_activeRecord);
        party.value = data[0];
        msg.value = false;
      } else {
        msg.value = true;
        party.value = null;
      }
    }
    loading.value = false;
  }
  console.log('prueba de ingrso');

  return (
    <div className="">
      <LogoCompany />
      <div className="my-8">
        <h1 className="text-3xl font-bold text-gray-600 text-center">
          Creación de Radicado PQRs
        </h1>
      </div>
      <div className="grid grid-cols-6 md:grid-cols-12 gap-4 pb-6">
        <div className="sm:col-start-2 col-span-3">
          <h3 style={{ color: "green" }}>
            Por favor llene el formulario con los datos correspondientes.{" "}
          </h3>
        </div>
        {/* <div className="col-start-8 col-span-2"> */}
        <StdButton
          onClick={handleSend}
          size="w-full"
          color="slate"
          content="Regresar"
          alingTextRight={true}
          style="mb-3 my-6 sm:mt-auto md:mt-auto lg:mt-auto mr-2 col-start-8 col-span-2"
        >
          <ArrowLeftIcon
            className="h-5 w-5 mr-2 -ml-1 text-white"
            aria-hidden="true"
          />
        </StdButton>
        <StdButton
          onClick={handleCancel}
          size="w-full"
          color="rose"
          content="Cancelar"
          alingTextRight={true}
          style="mb-3 my-6 sm:mt-auto md:mt-auto lg:mt-auto mr-2 col-span-2"
        >
          <XMarkIcon
            className="h-5 w-5 mr-2 -ml-1 text-white"
            aria-hidden="true"
          />
        </StdButton>
        {/* </div> */}
      </div>

      {!party.value ? (
        <div className="block sm:flex md:flex lg:flex gap-4 pb-6 columns-1 sm:columns-2 md:columns-2">
          <CharField
            field={fields.partyIdNumber}
            attrs={{level:"main"}}
            // onChange={handleField}
            data={id_number}
          />
          <StdButton
            onClick={handleSearch}
            size="w-full"
            loading={loading.value}
            // disabled={true}
            color="blue"
            content="crm.customer_service.search"
            style="mb-3 my-6 sm:mt-auto md:mt-auto lg:mt-auto"
          >
            <MagnifyingGlassIcon
              className="h-5 w-5 ml-3 text-white"
              aria-hidden="true"
            />
          </StdButton>
        </div>
      ) : (
        <Fragment>
          <p>asdassdads</p>
        <QuickForm ctxView={ViewFiduprevisora} level="main" />
        </Fragment>
      )}
      {msg && (
        <div className="my-2">
          <p className="text-lg text-rose-600 text-left py-4">
            No hay registros encontrados, verifique número su número de cédula.
          </p>
        </div>
      )}
    </div>
  );
}

export default CrmFiduprevisora;
