import React, { useState } from "react";
import { signal } from "@preact/signals-react";

import { FormattedMessage as FM } from "react-intl";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import DateField from "components/MeForm/DateField";

const isPopoverOpen = signal(false);
const filter_date = signal(null);

const DateRangeCustomSelect = (props) => {
  const onChange = () => {
    if (filter_date.value) {
      let _value = new Date(filter_date.value);
      // setDateStart("value");
      props.onClick(_value);
      isPopoverOpen.value = true;
    }
  };

  const dateField = {
    withChange: onChange,
    name: "filter_date",
    label: "date_range.filter_date",
  };

  const ATTRS = {
    level: "wizard",
  };

  return (
    <div className="relative" onBlur={() => (isPopoverOpen.value = true)}>
      <button
        onClick={() => (isPopoverOpen.value = !isPopoverOpen.value)}
        className="group bg-sky-200 border border-sky-300 shadow-sm rounded-md px-7 cursor-pointer pt-2 h-[74px] pb-2 flex flex-col justify-center w-20 items-center space-y-1 active:scale-95 active:shadow-x hover:bg-blue-presik"
      >
        <CalendarDaysIcon className="w-10 group-hover:text-white" />
        <span className="text-xs font-semibold uppercase group-hover:text-yellow-300 pb-2 text-cyan-700">
          <FM id={"date_range.select"} key={"date_range.select"} />
        </span>
      </button>
      {isPopoverOpen.value && (
        <div className="bg-white p-6 shadow-sm rounded-md border border-gray-200 absolute z-10 -left-28 w-[300px] flex flex-col top-20">
          <DateField field={dateField} data={filter_date} attrs={ATTRS} />
        </div>
      )}
    </div>
  );
};

export default DateRangeCustomSelect;
