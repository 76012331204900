import React, { useState } from "react";
import { FormattedMessage as FM } from "react-intl";
import date from "date-and-time";
import { signal } from "@preact/signals-react";

import DropdownField from "components/MeForm/DropdownField";
import StdButton from "components/Buttons/StdButton";
import IconButton from "components/Buttons/IconButton";
import DateField from "components/MeForm/DateField";
import ModalForm from "components/Modals/ModalForm";
import Sheet from "./Sheet";
import mainStore from "store/mainStore";
import proxy from "api/proxy";
import { useWizardStore } from "store/wizardStore";
import dates from "tools/dates";
import { useQuery } from "@tanstack/react-query";

const selectedDate = signal(new Date());

function RateCalendar(props) {
  let [modeView, setModeView] = useState("locations"); // list or sheet
  let [messageCtx, setMessageCtx] = useState(null);
  let [openModalShift, setOpenModalShift] = useState(false);
  let [headers, setHeaders] = useState(null);
  let [records, setRecords] = useState(null);
  let [filter, setFilter] = useState(null);

  const { record, storeMain, setRecord, reset } = mainStore();
  const { record: recordWiz, store: storeWiz } = useWizardStore();

  const multiselect = true;
  let toUpdate = new Map();
  let toRemove = [];
  let toCreate = {};

  const getDataSheet = async () => {
    let searchText = null;
    if (props.filter) {
      searchText = props.filter;
    }

    const start_date = dates.fmtDate2Tryton(selectedDate.value);
    const ctxArgs = {
      model: "hotel.calendar",
      method: "get_sheet",
      args: [
        {
          start_date: start_date,
          oc: props.oc,
        },
      ],
    };

    const { data } = await proxy.methodCall(ctxArgs);
    console.log("records ...........", data);
    // console.log("data ...........", data);
    // const [headers, records] = data;
    // setHeaders([headers]);
    // setRecords(records);
    // const headers = data.shift();
    // let res = [headers];
    // for (let [name_acco, rows] of data.entries()) {
    //   // let res = new Array(headers.length - 1).fill("-");
    //   res.push([{ value: name_acco, readOnly: true }]);
    // }
    return data;
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ["sheetData"],
    queryFn: getDataSheet,
  });

  const toSave = async () => {
    const model = "surveillance.schedule.shift";
    if (toUpdate.size > 0) {
      const values = toUpdate.values();
      for (const val of values) {
        delete val.readOnly;
        const data = {
          model: model,
          storeRec: val,
        };
        await proxy.saveQuery(data);
      }
    }

    const messageCtx = {
      type: "msgInfo",
      msg: "board.records_saved",
    };

    setMessageCtx(messageCtx);
    if (Object.keys(toCreate).length > 0) {
      for (const rec of Object.values(toCreate)) {
        delete rec.id;
        delete rec.location;
        delete rec.readOnly;
        await proxy.create(model, rec);
      }
    }
    if (toRemove.length > 0) {
      const args = { model: model, ids: toRemove };
      await proxy.remove(args);
    }

    await toReload();
  };

  const toAdd = async () => {};

  function onDoubleClickCell(rec) {}

  const closeEditModal = () => {
    setOpenModalShift(false);
    reset();
  };

  const toCloseSheet = () => {
    setMessageCtx(null);
  };

  function onDoubleClickRow(rec) {
    // if (rec && rec.id) {
    //   props.onSelectedRecord(rec);
    //   props.onClose();
    // }
  }

  function onClickRow(rec) {}

  // <StdButton
  //   color="green"
  //   onClick={setDataSheet}
  //   style="w-full"
  //   content={"schedule.scheduling"}
  // />

  function onChangeDate(field, value) {
    let value_ = date.parse(value, fmtDate);
    selectedDate.value = value_;
  }

  const attrsDate = {
    level: "wizard",
  };

  const field = {
    name: "calendar_date",
    labeled: false,
    onChange: onChangeDate,
  };

  console.log("prueba ...", data);
  return (
    <div className="w-full h-full px-4">
      {messageCtx && (
        <div className="" id={`surveillance-${messageCtx.type}`}>
          <p className="">
            <FM id={messageCtx.msg} />
          </p>
        </div>
      )}
      <div className="flex gap-4 my-2">
        <div className="w-2/3 flex gap-2">
          <DateField attrs={attrsDate} data={selectedDate} field={field} />
        </div>
      </div>
      <div>
        {data && (
          <Sheet
            onDoubleClickCell={onDoubleClickCell}
            filter={filter}
            records={data}
          />
        )}
      </div>
    </div>
  );
}

export default RateCalendar;
