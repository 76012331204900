import React from "react";
import { FormattedMessage as FM } from "react-intl";

// import { IconButton } from "./Constants/constants";

const WidgetSideStatusActivity = ({ tickets, day }) => {
  let dayCurrent = new Date(day);
  dayCurrent.setDate(dayCurrent.getDate() + 1);
  dayCurrent.setHours(23, 59, 59);
  let valueQuota = 0;
  let valueAvalible = 0;

  valueQuota = tickets.reduce((accumulator, ticket) => {
    return accumulator + ticket.quota;
  }, 0);

  valueAvalible = tickets.reduce((accumulator, ticket) => {
    return accumulator + ticket.available;
  }, 0);
  let totalSale = (valueAvalible * 100) / valueQuota;
  let totalSale_ = totalSale.toFixed(0);
  totalSale_ = 100 - totalSale_;

  return (
    <div className=" space-y-3 rounded-lg">
      <div className="flex flex-col space-y-2 ">
        <div className="space-x-3 flex justify-around text-center">
          <span className="text-1xl font-semibold">
            <FM id="activity.widget_side_status.revenue_today" />
          </span>{" "}
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="space-x-3 flex justify-around pb-2">
          <span className="text-5xl font-semibold">%{totalSale_}</span>{" "}
        </div>
        <hr className="mt-5" />
      </div>
    </div>
  );
};

export default WidgetSideStatusActivity;
