import React, { Fragment } from "react";

import proxy from "api/proxy";
import {
  BackspaceIcon,
  IdentificationIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import StdButton from "components/Buttons/StdButton";
import { classNames } from "tools/ui";
import { signal } from "@preact/signals-react";
import PureModal from "components/Modals/PureModal";

const idNumberCustomer = signal(null);
const showModal = signal(false);
const currentTurn = signal(null);

const STYLE_BUTTONS =
  "w-full text-center text-6xl p-3 bg-gray-200 border border-gray-300 rounded-md active:scale-95 active:bg-blue-presik active:text-white shadow-md transition-all duration-200";

const OnScreenKeyboard = ({ numbers, idControl }) => {
  const handleKeyPress = (number) => {
    let _number = number.toString();
    const _idNumberCustomer = idNumberCustomer.value ?? "";
    idNumberCustomer.value = _idNumberCustomer + _number;
  };

  const handleDelete = () => {
    let removeNumber = idNumberCustomer.value.slice(0, -1);
    idNumberCustomer.value = removeNumber;
  };

  const handleClear = () => {
    idNumberCustomer.value = "";
  };

  const InputNumberDocument = () => {
    return (
      <input
        type="text"
        className="w-full bg-gray-100 px-2 py-4 my-4 uppercase !text-4xl text-center rounded-md shadow-md border border-gray-300"
        placeholder="Digita tu numero"
        value={idNumberCustomer.value}
        disabled
      />
    );
  };

  const createTurn = async (e) => {
    e.preventDefault();
    if (!idNumberCustomer.value) {
      return false;
    }
    const record = {
      ticket_control: idControl.id,
      number_id: idNumberCustomer.value,
    };

    const model = "ticket_system.ticket";
    const fields_names = ["turn"];
    const { data: res, error } = await proxy.create(
      model,
      record,
      fields_names,
    );
    console.log(res);
    if (res) {
      const { turn } = res[0];
      console.log(turn, "turno");
      showModal.value = true;
      currentTurn.value = turn;
    }
  };

  const ContentModal = () => {
    return (
      <div className="py-28 bg-blue-presik text-white rounded-xl flex flex-col justify-center items-center">
        <XCircleIcon
          className="absolute w-20 h-20 top-5 right-5 active:scale-95 transition-all duration-200"
          onClick={() => closeModal()}
        />
        <span>Tu turno es:</span>
        <span className="text-9xl font-semibold text-yellow-500">
          {currentTurn.value}
        </span>
        <span>#Documento:</span>

        <div className="text-3xl flex space-x-2">
          <IdentificationIcon className="w-5" />
          <span>{idNumberCustomer.value}</span>
        </div>
        <StdButton
          color="yellowPresik"
          style="mt-5"
          onClick={() => closeModal()}
        >
          ENTENDIDO
        </StdButton>
      </div>
    );
  };

  const closeModal = () => {
    idNumberCustomer.value = "";
    showModal.value = false;
  };

  return (
    <Fragment>
      <div className="w-full max-w-2xl mx-auto bg-white px-6 py-10 shadow-md rounded-md">
        <h1 className="text-2xl font-semibold text-center">TOMADOR DE TURNO</h1>
        <p className="text-center text-gray-600">
          Agrega tu numero de documento para generar el ticket
        </p>
        <InputNumberDocument />
        <div className=" grid grid-cols-3 w-full gap-6">
          {numbers.map((number) => (
            <button
              key={number}
              className={STYLE_BUTTONS}
              onClick={() => handleKeyPress(number)}
            >
              {number}
            </button>
          ))}
          <button
            className={classNames(STYLE_BUTTONS)}
            onClick={() => handleDelete()}
          >
            <BackspaceIcon className="w-14 mx-auto text-gray-900" />
          </button>
          <button
            className={classNames(STYLE_BUTTONS, "bg-red-200")}
            onClick={() => handleClear()}
          >
            <XCircleIcon className="w-14 mx-auto text-red-900 " />
          </button>
        </div>
        <div>
          <StdButton
            color={"bluePresik"}
            style="w-full max-w-md mx-auto mt-8 !text-white !h-16 text-2xl"
            disabled={idNumberCustomer.value ? false : true}
            onClick={(e) => createTurn(e)}
          >
            GENERAR TURNO
          </StdButton>
        </div>
      </div>
      <PureModal
        open={showModal.value}
        onClose={() => closeModal()}
        backdrop={true}
      >
        <ContentModal />
      </PureModal>
    </Fragment>
  );
};

export default OnScreenKeyboard;
