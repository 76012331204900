import React, { useState, useEffect } from "react";
import { FormattedMessage as FM } from "react-intl";

const BodyTurn = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  const formatTime = (time) => {
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const Clock = () => {
    return (
      <div className="w-auto text-7xl font-medium">
        {formatTime(currentTime)}
      </div>
    );
  };

  return (
    <div className="flex flex-col justify-center items-center w-full h-full text-center">
      <div className="absolute top-1 left-2">
        <img
          src="https://www.laboratoriobolivar.com/images/logo.png"
          className="max-w-[280px] mb-5"
        />
      </div>
      <Clock />
      <div className="w-full mt-5">
        <span className="text-3xl font-medium">
          <FM id="ticket_system.ticket.attention_schedule" />
        </span>
        <div className="grid grid-cols-2 max-w-md mx-auto mt-3">
          <span>
            <b>
              <FM id="ticket_system.ticket.weekday" />
            </b>{" "}
            <br />
            7am - 5pm
          </span>
          <span>
            <b>
              <FM id="ticket_system.ticket.wekend" />
            </b>{" "}
            <br />
            7am - 12pm
          </span>
        </div>
      </div>
    </div>
  );
};

export default BodyTurn;
