import React, { useEffect } from "react";

import proxy from "api/proxy";
import tools from "tools/dates";
import TicketTurn from "./Components/TicketTurn";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import notificationSound from "./Assets/Audio/sound-ticket.wav";
import BodyTurn from "./Components/BodyTurn";

const today = tools.dateToday();
const dom = [
  ["ticket_control.date_ticket", ">=", today],
  ["state", "=", "busy"],
];
const fields = ["turn", "number_id", "position", "position.number"];

const WebTickekSystem = () => {
  const queryClient = useQueryClient();
  const queryTurnActive = useQuery(
    ["ticket_system.ticket", dom],
    async () => {
      let turns = [];
      if (dom) {
        const { data: res, error } = await proxy.search(
          "ticket_system.ticket",

          dom,
          fields,
        );
        turns = res;
      }

      return turns;
    },
    {
      staleTime: 5000,
    },
  );

  useEffect(() => {
    const interval = setInterval(() => {
      queryClient.invalidateQueries(["ticket_system.ticket", dom]);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  useEffect(() => {
    if (queryTurnActive.data && queryTurnActive.data.length > 0) {
      playNotificationSound();
    }
  }, [queryTurnActive.data]);

  return (
    <div className="w-screen h-screen bg-white p-4 rounded-md  overflow-hidden">
      <div className="bg-gray-100 h-full w-full p-4 ">
        <div className="flex h-full justify-between ">
          <div className="flex relative  justify-start items-center w-[60vw] h-full">
            <BodyTurn />
            <footer className="w-full p-4 absolute bg-blue-presik -bottom-4 rounded-b-md text-white -left-4 pr-4 overflow-hidden">
              <div className="scrolling-text">
                Recuerda presentarte en ayunas para que los exámenes sean
                precisos. Líneas: (607) 6 97 26 46. WhatsApp: 310 317 3879 -
                info@laboratoriobolivar.com
              </div>
            </footer>
          </div>
          <TicketTurn dataTurn={queryTurnActive?.data} />
        </div>
      </div>
    </div>
  );
};

export default WebTickekSystem;
