import React, { useRef, useState } from "react";
import date from "date-and-time";
import Flatpickr from "react-flatpickr";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import "flatpickr/dist/themes/airbnb.css";

import FormField from "./FormField";
import { classNames, colors } from "tools/ui";
import upStore from "store/upStore";

const fmt = "YYYY-MM-DD HH:mm:ss";

function DatetimeField(props) {
  const { field, attrs, data } = props;
  const { readOnly, name } = field;
  const { level } = attrs;
  // const { level, error, record, ctxView, parentRec } = attrs;
  const fp = useRef(null);
  const [state, setState] = useState(null);
  let initialDate = "";
  if (data.value) {
    initialDate = data.value;
  }
  let dateRef = useRef(null);
  dateRef.current = initialDate;

  function openCalendar() {
    if (state) {
      fp.current.flatpickr.close();
      setState(false);
    } else {
      setState(true);
      fp.current.flatpickr.open();
    }
  }

  function handleClose() {
    setState(false);
  }

  function handleDate(rangeDates) {
    if (rangeDates.length === 0) return;
    const _value = rangeDates[0];
    const value = date.format(_value, fmt);
    // dateRef.current = value;
    // props.onChange(props.name, _value);
    upStore(level, {
      fieldName: name,
      value: value,
      field: field,
    });
  }

  let color = "";
  if (fp.current) {
    fp.current.flatpickr.input.disabled = readOnly;
    if (state === null) {
      fp.current.flatpickr.close();
    }
  }
  if (readOnly) {
    color = colors["readOnly"];
  }

  const style = "outline-none w-full py-2 px-2 rounded-md wrap h-[38px]";
  const styleIcon = "text-start mr-1 h-6 w-6 my-auto text-zinc-600";
  const styleDiv =
    "flatpickr h-10 flex outline-none border border-slate-300 rounded-md focus:border-sky-400";

  const options = {
    enableTime: true,
    dateFormat: "Y-m-d  |  H:i",
  };

  return (
    <FormField {...field}>
      <div className={classNames(styleDiv, color)}>
        <Flatpickr
          ref={fp}
          options={options}
          className={classNames(style, color)}
          value={dateRef.current}
          onChange={handleDate}
          onClick={openCalendar}
          onClose={handleClose}
        />
        <CalendarDaysIcon
          onClick={openCalendar}
          className={classNames(styleIcon, color)}
          aria-hidden="true"
        />
      </div>
    </FormField>
  );
}

export default DatetimeField;
