import React, { useState, useEffect } from "react";
import store from "store";
import FullTable from "components/MeTable/FullTable";
import ModelSale from "../ModelSale";
import { useIdSeq } from "store/idSeq";
import { useWizardStore } from "store/wizardStore";
import mainStore from "store/mainStore";
import func from "../../../tools/functions";
import proxy from "api/proxy";

const ModalWizzardActivity = ({ onClose, ctxView }) => {
  const {
    store: storeRecord,
    updateRecord,
    updateStore,
    setRecord,
    record: activeRecord,
  } = mainStore();
  const { updateWizard, record: wizRecord } = useWizardStore();
  const { seq, increment } = useIdSeq();
  const ctxViewSale = ModelSale.ctxView();
  const session = store.get("ctxSession");

  useEffect(() => {
    fillActivities();
  }, [ctxView]);

  const addActivity = async (record) => {
    const _storeRecord = { ...storeRecord };
    let _activeRecord = { ...activeRecord };
    if (record.quantity > record.available) {
      alert(`Solo existen ${record.available} cupos para esta hora.`);
      return false;
    }

    const { data: activityAvailable } = await proxy.browse(
      "sale_activity.activity",
      [record.id],
      ["available"],
    );

    if (record.quantity > activityAvailable[0].available) {
      let msg =
        activityAvailable[0].available == 0
          ? "Ya no existen cupos disponibles para esta actividad."
          : `Solo existen ${activityAvailable[0].available} cupos para hora.`;
      alert(msg);
      return false;
    }
    const product = record["schedule."]["kind."]["product."];
    const sequence = seq;
    let line_ = {
      origin: {
        time_start: record.time_start,
        time_end: record.time_end,
        id: `sale_activity.activity,${record.id}`,
      },
      id: sequence,
      quantity: record.quantity,
      unit: product["sale_uom."],
      sale_price_taxed: product["sale_price_taxed"],
      sale_price_w_tax: product["sale_price_w_tax"],
      list_price: product["list_price"],
      base_price: product["sale_price_w_tax"],
      discount: 0,
      product: product,
    };

    let lineStore_ = {
      id: sequence,
      quantity: record.quantity,
      unit: product["sale_uom."]["id"],
      product: product.id,
      base_price: product["sale_price_w_tax"],
      origin: `sale_activity.activity,${record.id}`,
    };

    let lines = _activeRecord.lines;
    if (!_storeRecord.lines) {
      _storeRecord.lines = new Map();
      _storeRecord.lines.set("create", new Map());
    }
    let to_create = _storeRecord.lines.get("create");
    _storeRecord.salesman = session.user_employee || null;
    line_["unit_price"] = product.sale_price_w_tax.toFixed(2);
    lineStore_["unit_price"] = product.sale_price_w_tax.toFixed(2);
    line_["amount"] = (record.quantity * line_.sale_price_w_tax).toFixed(2);
    to_create.set(sequence, lineStore_);
    lines.set(sequence, { ...line_ });
    increment(sequence + -1);
    record.lines = lines;
    store.lines = _storeRecord.lines;
    const totalAmount = [...lines.values()].reduce(
      (accumulator, currentValue) => {
        const amount = parseFloat(currentValue.amount);
        return accumulator + amount;
      },
      0,
    );
    if (totalAmount >= 212060) {
      alert("El valor aplica para factura electrónica.");
      _activeRecord.invoice_type = "1";
      _storeRecord.invoice_type = "1";
    }
    _activeRecord.total_amount = totalAmount.toFixed(2);
    updateRecord(_activeRecord);
    updateStore(_storeRecord);
    updateWizard({
      amount: parseInt(totalAmount.toFixed(2)),
    });

    await createSaleRecord(_storeRecord);
    onClose();
  };

  const createSaleRecord = async (store) => {
    const _storeRecord = store;
    const _data = func.recToTryton(_storeRecord);
    const args = {
      model: "sale.sale",
      method: "send_order",
      args: [_data],
    };
    const { data: res, error } = await proxy.methodCall(args);
    if (!error) {
      const recordId = _storeRecord.id >= 0 ? _storeRecord.id : res.record.id;
      setRecord(recordId, "sale.sale", ctxViewSale);
    } else {
      console.log(error);
    }
  };

  ctxView.webfields.add.method = addActivity;

  const fillActivities = async () => {
    const today = new Date();
    const formattedTime = today.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const fields = [
      "quota",
      "time_start",
      "time_end",
      "available",
      "id",
      "state",
      "schedule",
      "schedule.date_activity",
      "schedule.kind",
      "schedule.kind.name",
      "schedule.kind.product.name",
      "schedule.kind.product.sale_price_taxed",
      "schedule.kind.product.list_price",
      "schedule.kind.product.sale_uom.name",
      "schedule.kind.product.sale_price_w_tax",
    ];
    const { data: res, error } = await proxy.search(
      "sale_activity.activity",
      ctxView.domain,
      fields,
    );
    res.sort(orderArray);
    const mapRecs = new Map();
    res.forEach((act) => {
      if (
        act.state == "scheduled" &&
        act.time_start >= formattedTime &&
        act.available > 0
      ) {
        act.id = act.id;
        (act.quantity = parseInt(1)), mapRecs.set(act.id, act);
      }
    });

    if (res) {
      updateWizard({ activities: mapRecs });
      // setRecords(mapRecs);
    }
  };

  const orderArray = (a, b) => {
    const hour1 = a.time_start;
    const hour2 = b.time_start;
    if (hour1 < hour2) {
      return -1;
    }
    if (hour1 > hour2) {
      return 1;
    }
    return 0;
  };

  return (
    <div id="hotel-booking-wizard-rooms" className="block">
      <FullTable
        records={wizRecord.activities}
        parentRec={{ fieldName: "activities", record: wizRecord }}
        isLoading={false}
        limit={30}
        // updateRecords={updateRecords}
        onChangeView={() => console.log("cambio de vista")}
        ctxView={ctxView}
        level="wizard"
      />
    </div>
  );
};

export default ModalWizzardActivity;
