import React from "react";

import Divider from "components/MeForm/Divider";
import CardSectionRow from "./CardSectionRow";
import { classNames } from "tools/ui";

function ChartCard({
  children,
  header,
  header_meta,
  desc,
  desc_meta,
  getReport,
  style,
}) {
  function onClick() {
    getReport();
  }

  const base = "h-fit shadow-lg rounded-lg px-4 pt-3 pb-3 bg-white w-full my-4";
  const zoneClickable = "active:scale-95 active:bg-slate-100 active:px-3";
  return (
    <div id="chart-card" className={classNames(base, style)}>
      {children}
      <div className={classNames(zoneClickable)} onClick={onClick}>
        <Divider className="" />
        <CardSectionRow
          content1={header}
          content2={header_meta}
          style="text-slate-800"
        />
        {desc && (
          <CardSectionRow
            style="text-sky-500"
            content1={desc}
            content2={desc_meta}
          />
        )}
      </div>
    </div>
  );
}

export default ChartCard;
