import React from "react";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";

const SectionNoData = ({ text = null, children, colors }) => {
  let bgColor = colors?.colorPrimary || "bg-blue-presik";

  return (
    <div className={`h-32 w-full  shadow-md rounded-md my-5 p-2 ${bgColor}`}>
      <div className=" border border-dashed flex items-center justify-center just  w-full h-full space-x-7">
        <CalendarDaysIcon className="w-16 text-yellow-300" />
        <div>
          <h2 className="  text-white text-left text-3xl flex flex-col">
            {text}
          </h2>
          {children && <div className="mt-2">{children}</div>}
        </div>
      </div>
    </div>
  );
};

export default SectionNoData;
