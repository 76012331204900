import React from "react";
import { FormattedMessage as FM } from "react-intl";

const Footer = ({ colors }) => {
  const bgColor = colors?.colorPrimary || "bg-blue-presik";
  return (
    <footer
      className={`px-10 py-5  text-white w-screen md:absolute z-20 bottom-0 ${bgColor}`}
    >
      <FM id="app.booking.right_reserved" />{" "}
      <b>
        <FM id="app.booking.copyright" />
      </b>
    </footer>
  );
};

export default Footer;
