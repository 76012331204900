import ModalWizzardActivity from "./components/ModalWizzardActivity";
ModalWizzardActivity;

const getView = () => {
  let DictCtxView = {
    model: "sale_activity.activity",
    form_action: [],
    table_action: [],
    title: { field: "schedule", model: true },

    domain: [],

    webfields: {
      quota: { type: "numeric" },
      time_start: { type: "char" },
      time_end: { type: "char" },
      available: { type: "integer" },

      quantity: {
        type: "integer",
        editable: true,
        default: 1,
      },
      state: { type: "char" },
      schedule: {
        type: "many2one",
        recSearch: () => [],
        model: "sale_activity.schedule",
        searchable: true,
        // readOnly: true,
        attrs: [
          "date_activity",
          "note",
          "kind.name",
          "kind.product.name",
          "kind.product.list_price",
          "kind.product.sale_uom",
          "kind.employee.party.name",
        ],
      },
      "schedule.kind.name": { type: "char" },

      employee: {
        type: "many2one",
        recSearch: () => [],
        model: "company.employee",
        searchable: true,
        attrs: ["id, party.name"],
        // readOnly: true,
      },

      add: {
        type: "button",
        color: "bluePresik",
        icon: "fi fi-rr-add",
      },
    },
    webtree: [
      { name: "time_start", width: "30%" },
      { name: "time_end", width: "30%" },
      { name: "quota", width: "30%", widget: "increment" },
      { name: "available", width: "10%" },
      // { name: "state", width: "30%" },
      { name: "quantity", widget: "increment" },
      { name: "add", width: "30%" },
    ],
    webform: [],
  };

  return DictCtxView;
};

export default { ctxView: getView };
