import useMainStore from "store/mainStore";
import useChildStore from "store/childStore";
import useGrandStore from "store/grandStore";
import { useWizardStore } from "store/wizardStore";
import editTable from "./editTable";

async function updateMain({ field, fieldName, value, valueActive }) {
  const { record, store, updateRecord } = useMainStore.getState();
  if (valueActive) {
    record[fieldName] = valueActive;
  } else {
    record[fieldName] = value;
  }
  store[fieldName] = value;
  const parentRecord = null;
  if (field?.withChange) {
    await field.withChange(record, store, parentRecord);
    await updateRecord(record);
  }
  if (field?.dependents) {
    // This force re-render in form
    await updateRecord({ ...record });
  }
}

async function updateChild({
  field,
  fieldName,
  value,
  parentRec,
  autoSave,
  recId,
}) {
  let { record, store, updateRecord } = useChildStore.getState();
  store.id = recId ?? record.id;

  if (recId) {
    const { store: parentStore } = useMainStore.getState();
    const records = parentRec.record[parentRec.fieldName];
    record = records.get(recId);
    updateRecord(record);
    const mapStore = parentStore[parentRec.fieldName];
    store = mapStore?.get(recId) ?? store;
  }
  record[fieldName] = value;
  store[fieldName] = value;
  // FIXME
  // const parentRec = { record: activeRecord };

  if (value instanceof Object && value.id) {
    store[fieldName] = value.id;
  }
  if (field?.withChange) {
    await field.withChange(record, store, parentRec);
    await updateRecord(record);
  }
  if (field?.dependents) {
    // This force re-render in form
    await updateRecord({ ...record });
  }
  // FIXME: this lines add automatic data to table (one2many), maybe is not
  // neccesary
  if (parentRec && recId) {
    const action = recId > 0 ? "write" : "create";
    const records = [record];
    const level = "child";
    const fieldNameParent = parentRec.fieldName;
    await editTable({ level, action, records, fieldName: fieldNameParent });
  }
}

async function updateGrand({ field, fieldName, value, parentRec }) {
  const { record, store, updateRecord } = useGrandStore.getState();
  record[fieldName] = value;
  store[fieldName] = value;
  if (value instanceof Object && value.id) {
    store[fieldName] = value.id;
  }
  if (field && field.withChange) {
    await field.withChange(record, store, parentRec);
    await updateRecord(record);
  }
}

async function updateWizard({ field, fieldName, value, parentRec, recId }) {
  const {
    record,
    store,
    updateWizard: _updateWizard,
  } = useWizardStore.getState();
  if (field.type === "many2one" || !parentRec) {
    record[fieldName] = value;
    store[fieldName] = value;

    if (value instanceof Object && value.id) {
      store[fieldName] = value.id;
    }
    if (field && field.withChange) {
      await field.withChange(record, store, parentRec);
      _updateWizard(record);
    }
  } else {
    const mapStore = store[parentRec.fieldName];
    let rowStore = mapStore.get(recId);
    rowStore[fieldName] = value;
    const level = "wizard";
    const action = "write";
    const records = [rowStore];
    const fieldNameParent = parentRec.fieldName;
    await editTable({ level, action, records, fieldName: fieldNameParent });
  }
}

async function upStore(level, args) {
  let updated;
  if (level === "main") {
    updateMain(args);
  } else if (level === "child") {
    // this option is not possible because the object must have other attributes
    // such as fieldName
    // args.parentRec = useMainStore.getState().record;
    updated = updateChild(args);
  } else if (level === "grand") {
    updateGrand(args);
  } else if (["wizard", "many2one"].includes(level)) {
    updateWizard(args);
  }
  return updated;
}

export default upStore;
