import React from "react";
import { FormattedMessage as FM } from "react-intl";

function LabelRowSection(props) {
  let value = props.data;
  if (props.translate) {
    value = <FM id={props.data} />;
  }
  return (
    <div className="py-3">
      <p className="text-lg">
        <FM id={props.label} name={props.label} />
      </p>
      <p className="text-lg font-bold text-gray-700">{value}</p>
    </div>
  );
}

export default LabelRowSection;
