import proxy from "api/proxy";
import store from "store";

import ModelSaleLine from "./ModelSaleLine";
import ModelParty from "./ModelParty";
import WizardAddPayment from "./WizardAddPayment";
import ProductDiscount from "./components/ProductDiscount";
import fetchLocalAPI from "printing/printing";
import funcs from "tools/functions";
import { ModalWizzardFolios } from "./components/ModalWizardFolios";
import { ModalWizzardEvents } from "./components/ModalWizardEvents";
import QRCode from "qrcode";

const stateColors = {
  draft: "gray",
  processing: "amber",
  done: "lime",
  cancelled: "rose",
  transferred: "sky",
};

const onPrintBrowser = async (record) => {
  const model = "sale.sale";
  const fieldsActi = ["id", "time_start", "time_end"];
  const session = store.get("ctxSession");
  const terminal = funcs.getCookie();
  const args = { user_id: session.user, device_id: Number(terminal.device_id) };
  const INVOICE_TYPE = {
    P: "SISTEMA POS:",
    1: "FACTURA DE VENTA ELECTRONICA:",
    M: "CORTESIA:",
  };
  const { data: ctxPrinting } = await proxy.methodCall({
    model: model,
    method: "get_printing_context",
    args: [args],
  });
  const argSale = {
    type_doc: "invoice",
    sale_id: record.id,
  };
  const { data: sale } = await proxy.methodCall({
    model: model,
    method: "get_data",
    args: [argSale],
  });
  const styleSeparator =
    "border-top: 1px dashed; margin-top:0.5cm; margin-bottom:0.5cm";

  let infoTransfer = "";
  let infoTransferRoom = "";
  let _sale = { ...record, ...sale };
  console.log("esta es la venta", _sale);
  _sale.order = sale?.order;
  _sale.payment_term = sale?.payment_term;
  _sale.create_date = new Date(record.sale_date);
  _sale.create_date = _sale.create_date.toLocaleDateString();
  _sale.party_address = record.party.address;
  if (_sale.courtesy) {
    _sale.total_discount_amount = _sale["lines."].reduce((total, item) => {
      return total + item.base_price * item.quantity;
    }, 0);
  }
  let imageQr = "";
  if (sale && sale.qr_code) {
    imageQr = await getQrCode(sale?.qr_code, 30, 30);
  }

  if (record.state !== "transferred" && record.invoice_type != "M") {
    let origins = record["lines."].map((item) => item.origin.id);
    const { data: activity } = await proxy.browse(
      "sale_activity.activity",
      origins,
      fieldsActi,
    );
    // _sale = { ...record };
    _sale.party = record.party.rec_name;
    _sale.party_id_number = record.party.id_number;
    _sale.party_phone = record.party.mobile;

    _sale.untaxed_amount = _sale.total_amount;
    const _saleLine = _sale["lines."].map((act) => {
      let matchingActivity = activity?.find((a) => a.id === act.origin.id);
      let timeStart = matchingActivity?.time_start;
      timeStart = timeStart.slice(0, -3);
      let name = act.product.name;
      const dataInvoice = {
        name: `${timeStart} ${name}`,
        origin: act.origin,
        unit_price_w_tax: act.amount,
        amount_w_tax: act.product.amount_w_tax,
        quantity: act.quantity,
        taxes: act.taxes,
        discount: act.discount,
      };
      return dataInvoice;
    });
    _sale.lines = _saleLine;
  } else {
    infoTransfer =
      "<div style='text-align:center; margin-top: 1.5cm; border-top: 1px dashed;margin-bottom: 0.5cm; padding-top: 0.1cm;'>Firma</div>";

    infoTransferRoom = `<div>${_sale.description}</div>`;
    _sale.party = record.party.rec_name;
    _sale.party_id_number = record["party."].id_number;
    _sale.party_address = record["party."].address;
    _sale.party_phone = record["party."].mobile;
    _sale.payment_term =
      _sale.state == "transferred" ? "Transferido" : "Cortesia";

    let origins2 = record["lines."].map((item) => item.origin);
    let ids = origins2.map((item) => item.id);
    const { data: activity2 } = await proxy.browse(
      "sale_activity.activity",
      ids,
      fieldsActi,
    );
    let _saleLine = _sale["lines."].map((act) => {
      let matchingActivity = activity2.find((a) => a.id === act.origin.id);

      let timeStart = matchingActivity ? matchingActivity?.time_start : "";
      timeStart = timeStart.slice(0, -3);
      let name = act.product.name;
      return {
        name: `${timeStart} ${name}`,
        origin: act.origin,
        unit_price_w_tax: act.amount,
        amount_w_tax: act.amount,
        quantity: act.quantity,
        taxes: act.product.taxes,
        discount: act.discount,
      };
    });

    _sale.lines = _saleLine;
  }
  let html = `
    <div style="margin-top: 0.5cm; margin-bottom: 0.5cm; display: flex; justify-content: space-between; border-top: 1px dashed; border-bottom: 1px dashed; padding: 2mm;">
      <div>Articulo</div>
      <div>Subtotal</div>
    </div>
    ${_sale.lines
      .map(
        (item) => `
      <div style="display: flex; justify-content: space-between;margin-bottom: 0.1cm;">
        <div style="width:70%">${item?.name}  ${
          item?.quantity != 1 ? "x " + item?.quantity : ""
        } ${item?.discount ? "DESC - " + item?.discount : ""}</div>
        <div style="width:30% text-align:rigth">${item?.unit_price_w_tax}</div>
      </div>
    `,
      )
      .join("")}
    <div style="display: flex; justify-content: space-between;">
      <div>Impuestos:</div>
      <div style="text-align: right;">0</div>
    </div>
    <div style="display: flex; justify-content: space-between; margin-top: 0.5cm; ">
    <div>Total:</div>
    <div style="text-align: right;">
    <div style="border-top: 1px dashed; width: 20mm; margin-bottom: 0.1cm; "></div>
    ${_sale.paid_amount || _sale.total_amount}
    </div>
    </div>
    <div style="display: flex; justify-content: space-between;">
    ${
      _sale.courtesy &&
      `<div>Total Complimentary:</div>
      <div style="text-align: right;">
      ${_sale.total_discount_amount}
      </div>`
    }
    </div>
    <div style="margin-top: 0.3cm; text-align: center"><b>Por favor llegar al punto de encuentro 15
    minutos antes de la actividad</b></div>
  `;

  let htmlTax;
  if (record.state != "transferred" && record.invoice_type != "M") {
    htmlTax = `<div>DETALLE DE IMPUESTOS</div>
  <div
    style="${styleSeparator}; border-top:1px dashed ; border-botton:1px dashed; margin-top: 0.5cm;
  margin-bottom: 0.5cm;"
  ></div>
  <div style="display: flex; gap: 0.2cm; flex-direction: column">
    <div style="display: flex; justify-content: space-between">
      <div>MEDIO PAGO</div>
      <div># APROB</div>
      <div>VALOR</div>
    </div>
    ${sale.payments
      .map(
        (item) => `
        <div style="display: flex; justify-content: space-between">
        <div>${item.name}</div>
        <div>${item?.voucher || ""} </div>
        <div>${item.amount} </div>
      </div>
    `,
      )
      .join("")}

  </div>
  <div
    style="${styleSeparator}; border-top:1px dashed ; border-botton:1px dashed; margin-top: 0.5cm;
margin-bottom: 0.5cm;"
  ></div>
  <div style="display: flex; justify-content: space-between">
    <div>No ITEMS:</div>
    <div>${_sale.lines.length}</div>
  </div>
  <div style="display: flex; justify-content: space-between">
    <div>No PEDIDO:</div>
    <div>${_sale.order}</div>
  </div>
  <div style="display: flex; justify-content: space-between">
    <div>CAJA No.:</div>
    <div>${ctxPrinting.sale_device}</div>
  </div>
  <div style="display: flex; justify-content: space-between">
    <div>CAJERO:</div>
    <div>${ctxPrinting.user}</div>
  </div>
  <div style="display: flex; justify-content: space-between">
  <div>VENDEDOR:</div>
  <div>${sale?.agent}</div>
</div>
  <div
  style="${styleSeparator}; border-top:1px dashed ; border-botton:1px dashed; margin-top: 0.5cm;
margin-bottom: 0.5cm;"
></div>
<div>Autorizacion de facturacion ${
      ctxPrinting.authorizations[sale.invoice_type].kind
    } No ${ctxPrinting.authorizations[sale.invoice_type].number} del ${
      ctxPrinting.authorizations[sale.invoice_type].start_date_auth
    } hasta ${
      ctxPrinting.authorizations[sale.invoice_type].end_date_auth
    }, habilita desde ${ctxPrinting.authorizations[sale.invoice_type].prefix}${
      ctxPrinting.authorizations[sale.invoice_type].to_auth
    } a ${ctxPrinting.authorizations[sale.invoice_type].prefix}${
      ctxPrinting.authorizations[sale.invoice_type].from_auth
    }</div>
<div style = "width:100%;  word-wrap: break-word; margin-top: 0.2cm;">CUFE:${
      sale.invoice_type == "1" ? sale.cufe : ""
    }</div>
<div style= "max-width: 140px; margin: 0px 50;">${imageQr}</div>`;
  }
  const title =
    record.state !== "transferred"
      ? INVOICE_TYPE[record.invoice_type]
      : "TRANSFERIDO";
  console.log(sale, "this is sale for get number");
  const number = _sale?.number ?? _sale?.order;
  const typeSystem = ` <div>${title} ${number}</div>`;

  const contenidoHTML = `    <div style="width: 62mm; padding: 0.1cm; font-family: monospace; font-size: 2.8mm">
  <div style="text-align: center; margin-bottom: 0.5cm" >
    <div style="margin-bottom: 0.5cm"><center><img src="https://res.cloudinary.com/dl4sdiukx/image/upload/v1698251404/fc7shgxveracpffl8ie3.png" width="60%" > </center></div>
    <div>${ctxPrinting.company}</div>
    <div>${ctxPrinting.shop}</div>
    <div>NIT: ${ctxPrinting.id_number} ${ctxPrinting.regime_tax}</div>
    <div>${ctxPrinting.street}</div>
    <div>${ctxPrinting.city} Telefono: ${ctxPrinting.phone}</div>
  </div>
  ${typeSystem}
  <div>Fecha: ${_sale.create_date}</div>
  <div style="${styleSeparator}"></div>
  <div>
    <div>Cliente: ${_sale.party}</div>
    ${infoTransferRoom ?? ""}
    ${_sale.description ? `<div>Nombre: ${_sale?.description}</div>` : ""}
    <div>ID: ${_sale.party_id_number}</div>
    <div>Direccion: ${_sale?.party_address || ""}</div>
    <div>Telefono: ${_sale.party_phone}</div>
    <div>Forma de Pago: ${_sale.payment_term}</div>
  </div>
  ${html}
  <div
    style="${styleSeparator}; border-top:1px dashed ; border-botton:1px dashed; margin-top: 0.5cm;
    margin-bottom: 0.5cm;"
  ></div>
  ${htmlTax || ""}
  ${infoTransfer || ""}
  <div>Fecha de creación: ${_sale.create_date} </div>
  <div style="margin-top: 0.5cm; text-align:center">NIT: 900803782-2</div>
  <div style=" text-align:center">SOFTWARE TRYTON - www.presik.com</div>
</div>
<div>
</div>
`;

  const ventana = window.open("", "", "width=500,height=600");
  ventana.document.open();
  ventana.document.write(contenidoHTML);
  ventana.document.close();
  ventana.print();
};
function getQrCode(url) {
  return QRCode.toString(url, { type: "svg" });
}
const onPrint = async (record) => {
  const model = "sale.sale";
  const session = store.get("ctxSession");
  const terminal = funcs.getCookie();
  const args = { user_id: session.user, device_id: Number(terminal.device_id) };
  const { data: ctxPrinting } = await proxy.methodCall({
    model: model,
    method: "get_printing_context",
    args: [args],
  });

  const apiPrint = ctxPrinting.local_printer;
  const argSale = {
    type_doc: "invoice",
    sale_id: record.id,
  };
  const { data: sale } = await proxy.methodCall({
    model: model,
    method: "get_data",
    args: [argSale],
  });
  const origins = sale.lines.map((item) => item.origin);
  const fieldsActi = ["id", "time_start", "time_end"];
  const { data: activity } = await proxy.browse(
    "sale_activity.activity",
    origins,
    fieldsActi,
  );
  let _sale = { ...sale };
  const _saleLine = sale.lines.map((act) => {
    const matchingActivity = activity.find((a) => a.id === act.origin);
    let timeStart = matchingActivity ? matchingActivity.time_start : "";
    timeStart = timeStart.slice(0, -3);
    let name = act.name;
    return {
      name: `${timeStart} ${name}`,
      origin: act.origin,
      unit_price_w_tax: act.unit_price_w_tax,
      amount_w_tax: act.amount_w_tax,
      quantity: act.quantity,
      taxes: act.taxes,
      discount: act.discount,
    };
  });
  _sale.lines = _saleLine;

  const obj = { record: _sale, context: ctxPrinting };
  const printer = new fetchLocalAPI(apiPrint);
  const { data, error } = await printer.post("/print_invoice", obj);
  window.alert(data ?? error);
};

const defaultShop = async () => {
  const terminal = funcs.getCookie();
  if (terminal.shop_id) {
    return { id: terminal.shop_id, name: terminal.shop_name };
  }
};

const defaultPaymentTerm = async () => {
  // const dom = [["id", "=", session.shop]];
  const dom = [];
  const fields = ["id", "rec_name"];
  const { data } = await proxy.search(
    "account.invoice.payment_term",
    dom,
    fields,
    1,
  );
  return data[0];
};

const defaultListPrice = async () => {
  const session = store.get("ctxSession");
  const dom = [["id", "=", session.shop]];
  const fields = ["id", "rec_name"];
  const { data } = await proxy.search("product.price_list", dom, fields, 1);
  return data[0];
};

const onChangeLines = (activeRecord, fieldValue) => {
  if (activeRecord.lines) {
    let total_amount = 0;
    for (let l of fieldValue.values()) {
      total_amount += l.amount;
    }
    activeRecord["total_amount"] = total_amount;
  }
  return activeRecord;
};

const visiblePrint = (name, record) => {
  let res = false;
  if (
    (record.state === "draft" && record.invoice_type == "M" && record.number) ||
    record.state === "processing" ||
    record.state === "transferred" ||
    record.state === "done"
  ) {
    res = true;
  }
  return res;
};

const visibleFolio = (name, record) => {
  let res = false;
  if (record.state === "draft" && record.lines && record.lines.size > 0) {
    res = true;
  }
  return res;
};

const disabledField = (record) => {
  let res = false;
  if (record.state !== "draft") {
    res = true;
  }
  return res;
};

const disabledReservation = (record) => {
  let res = true;
  const session = store.get("ctxSession");
  const sellerPermits = session.groups.some(
    (group) => group.name === "Venta en Reserva",
  );
  if (record.state !== "draft" || sellerPermits) {
    res = false;
  }
  return res;
};

const visibleDiscount = (name, record) => {
  let res = false;
  if (record.state === "draft" && record.id > 0) {
    res = true;
  }
  return res;
};

const visiblePay = (name, record) => {
  let res = false;
  if (
    record.lines &&
    record.lines.size > 0 &&
    record.party &&
    record.residual_amount != 0 &&
    record.state !== "transferred"
  ) {
    res = true;
  }
  return res;
};

const getSaleDevice = async () => {
  const terminal = funcs.getCookie();
  if (terminal.device_id) {
    return { id: terminal.device_id, name: terminal.device_name };
  }
};

// const getOptionsAsync = async () => {
//   const res = await proxy.getFields("sale.line", []);
//   console.log("--------resultadosale", res);
// };
// getOptionsAsync();

const getFilters = () => {
  return {
    draft: [["state", "=", "draft"]],
    done: [["state", "=", "done"]],
    transferred: [["state", "=", "transferred"]],
  };
};

const getView = (config) => {
  const session = store.get("ctxSession");
  let DictCtxView = {
    model: "sale.sale",
    activeSearch: true,
    filters: getFilters,
    domain: [["shop", "=", session.shop]],
    form_action: ["save", "edit", "add"], // options: ['save', 'delete']
    table_action: ["open"], // options: ['open', 'delete', 'edit', 'add']
    pagination: [],
    limit: 20,
    tags: {
      state: stateColors,
    },
    selectable: null, // Options for table rows: null, multi, one
    title: { field: "numeric", model: true },
    webfields: {
      id: { type: "integer", readOnly: true },
      name: { type: "name", readOnly: true },
      number: { type: "char", readOnly: true, searchable: true },
      "party.name": {
        type: "char",
        searchable: true,
      },
      "party.address": {
        type: "char",
      },
      "party.id_number": {
        type: "char",
        searchable: true,
      },
      "party.addresses": {
        type: "char",
      },
      "party.phone": {
        type: "char",
      },
      "party.mobile": {
        type: "char",
        call: ["mobile", "whatsapp"],
      },
      "party.account_payable": {
        type: "many2one",
      },
      "party.account_receivable": {
        type: "many2one",
      },
      party: {
        type: "many2one",
        model: "party.party",
        ctxView: ModelParty.ctxView(),
        required: true,
        searchable: true,
        default: {
          id_number: "222222222222",
          id: 34438,
          addresses: [27934],
          mobile: "",
          phone: "1",
          address: "VILLAVICENCIO",
          account_payable: 514,
          account_receivable: 334,
          rec_name: "CONSUMIDOR FINAL",
        },
        attrs: [
          "account_receivable",
          "mobile",
          "account_payable",
          // "address",
          "addresses",
          "phone",
          "id_number",
        ],
        readOnly: disabledField,
      },

      create_uid: {
        type: "many2one",
        model: "res.user",
        readOnly: true,
      },
      courtesy: {
        type: "boolean",
        readOnly: true,
      },
      payment_term: {
        type: "many2one",
        required: true,
        model: "account.invoice.payment_term",
        // options: getOptions(),
        translate: true,
        default: defaultPaymentTerm,
        readOnly: true,
      },
      shop: {
        type: "many2one",
        model: "sale.shop",
        readOnly: true,
        default: defaultShop,
        required: true,
      },
      invoice_address: {
        type: "many2one",
        model: "party.address",
        recSearch: () => [],
      },

      sale_date: {
        type: "date",
        readOnly: true,
        default: () => new Date(),
        required: true,
      },

      source: {
        type: "many2one",
        model: "sale.source",
        readOnly: true,
      },

      invoice_method: {
        type: "char",
        required: false,
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "draft",
        tags: stateColors,
      },

      residual_amount: {
        type: "numeric",
        readOnly: true,
      },
      paid_amount: {
        type: "numeric",
        readOnly: true,
      },
      reservation: {
        type: "boolean",
        readOnly: disabledReservation,
      },
      total_amount: {
        type: "numeric",
        readOnly: true,
        // recibar esta funcion si es necesaria para el proceso
        // function: getTotalAmount,
      },
      comment: {
        type: "char",
        searchable: true,
        readOnly: disabledField,
      },
      description: {
        type: "char",
        searchable: true,
        readOnly: disabledField,
      },
      sale_device: {
        type: "many2one",
        model: "sale.device",
        recSearch: () => [],
        required: true,
        default: getSaleDevice,
        readOnly: true,
      },

      price_list: {
        type: "many2one",
        recSearch: () => [],
        model: "product.price_list",
        required: false,
        default: defaultListPrice,
        readOnly: true,
      },

      invoice_type: {
        type: "radio",
        options: [
          // { value: "P", text: "POS" },
          { value: "1", text: "ELECTRONICA" },
          // { value: "M", text: "AC" },
        ],
        default: "1",
        required: true,
        readOnly: disabledField,
      },

      lines: {
        type: "one2many",
        model: "sale.line",
        ctxView: ModelSaleLine.ctxView(config),
        withChange: onChangeLines,
      },
      agent: {
        type: "many2one",
        model: "commission.agent",
        searchable: true,
      },
      pay: {
        type: "button",
        button_method: "",
        visible: ProductDiscount,
        color: "blue",
        onSuccessMsg: "ORDEN COMANDADA...!",
      },
      add_payment: {
        type: "button_wizard",
        Component: WizardAddPayment,
        color: "lime",
        icon: "fi fi-rr-add",
        style: "w-10",
        visible: visiblePay,
        resetWizard: false,
      },
      folios: {
        type: "button_wizard",
        Component: ModalWizzardFolios,
        color: "blue",
        icon: "fi fi-rr-add",
        style: "w-10",
        visible: visibleFolio,
        resetWizard: false,
      },
      transferred_events: {
        type: "button_wizard",
        Component: ModalWizzardEvents,
        color: "yellow",
        icon: "fi fi-rr-add",
        style: "w-10",
        visible: visibleFolio,
        resetWizard: false,
      },
      add_discount: {
        type: "button_wizard",
        Component: ProductDiscount,
        color: "blue",
        icon: "fi fi-rr-add",
        visible: visibleDiscount,
        resetWizard: false,
      },
      pay_method: {
        type: "button",
        method: "create_sale",
        visible: visiblePay,
        style: "p-22",
      },
      add_courtesy: {
        type: "button",
        method: "set_courtesy",
        visible: visiblePay,
        style: "p-22",
      },
      print: {
        type: "button",
        method: onPrint,
        visible: visiblePrint,
        // size: "w-32",
        color: "amber",
        iconRight: "fi fi-rr-print",
      },

      printBrowser: {
        type: "button",
        method: onPrintBrowser,
        visible: visiblePrint,
        // size: "w-32",
        color: "amber",
        iconRight: "fi fi-rr-print",
        loading: false,
      },
    },
    webtree: [
      { name: "number", width: "20%" },
      { name: "party.name" },
      // { name: "comment" },
      { name: "description" },
      { name: "party.id_number" },
      { name: "party.mobile", widget: "call-action" },
      { name: "create_uid" },
      { name: "sale_date" },
      { name: "reservation" },
      { name: "courtesy" },
      { name: "paid_amount" },
      { name: "state", widget: "badge" },
    ],
    webform: [
      { name: "party", widget: "search_add_new", edit: false },
      {
        id: "customer",
        grid: [{ name: "number" }, { name: "sale_date" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "consumer_party",
        grid: [
          // { name: "comment" },
          { name: "description" },
          { name: "invoice_type" },
          { name: "shop" },
          { name: "sale_device" },
          { name: "add_discount" },
          { name: "reservation" },
          { name: "courtesy" },
          { name: "agent" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      { name: "lines", component: "modal" },

      {
        id: "paid",
        grid: [
          { name: "total_amount" },
          { name: "paid_amount" },
          { name: "residual_amount" },
          { name: "state", width: "7%" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      {
        id: "actions",
        grid: [
          { name: "add_payment" },
          { name: "folios" },
          { name: "transferred_events" },
          { name: "add_courtesy" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      {
        id: "print",
        grid: [{ name: "printBrowser" }],
        size: [1, 4],
        span: "col-span-2",
      },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
