import React, { useContext } from "react";
import { useDrop } from "react-dnd";

import { tableStyle } from "./constants";
import CalendarContext from "./CalendarContext";
import { classNames } from "tools/ui";

function Room(props) {
  // load default context
  const context = useContext(CalendarContext);
  const { day, room, children } = props;

  // enable drop
  const [{ isOver }, drop] = useDrop({
    accept: "booking",
    drop: (singleBookingDraggableItem) => {
      context.actionMoveBooking(
        singleBookingDraggableItem.singleBooking,
        room.id,
        day,
      );
    },

    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const clickHandler = () => {
    context.actionOpenPopup({
      room_id: room.id,
      from_date: day,
      to_date: day,
    });
  };

  return (
    <td
      id="calendar-cell-empty"
      ref={drop}
      className={classNames(tableStyle.cellStyle)}
      key={day.getTime()}
      onClick={clickHandler}
    >
      {children}
    </td>
  );
}

export default Room;
