import React from "react";
import { FormattedMessage as FM } from "react-intl";

import { classNames } from "tools/ui";

const base =
  "text-xs inline-flex py-2 my-auto px-2 text-center uppercase rounded-xl last:mr-0 mr-3";

const COLORS = {
  slate: ["bg-slate-200", "text-slate-700"],
  indigo: ["bg-indigo-200", "text-indigo-700"],
  sky: ["bg-sky-200", "text-sky-700"],
  amber: ["bg-amber-200", "text-amber-700"],
  lime: ["bg-lime-200", "text-lime-700"],
  rose: ["bg-rose-200", "text-rose-700"],
  pink: ["bg-pink-200", "text-pink-700"],
  orange: ["bg-orange-200", "text-orange-700"],
  gray: ["bg-gray-200", "text-gray-700"],
  emerald: ["bg-emerald-200", "text-emerald-700"],
  black: ["bg-gray-700", "text-gray-200"],
};

function SectionRowTags({ ctxView }) {
  const { tags, model } = ctxView;

  function tagLabel(name, color) {
    let [bgColor, txtColor] = COLORS[color];
    return (
      <span key={color} className={classNames(base, bgColor, txtColor)}>
        <FM id={`${model}.${name}`} />
      </span>
    );
  }

  return (
    <div id="section-row-tags" className="my-auto flex flex-col ">
      {Object.entries(tags).map(([tagName, values]) => (
        <div
          key={tagName}
          className="p-2 flex flex-wrap md:flex-nowrap gap-1 md:space-y-0 md:flex-row items-center align-middle -visible"
        >
          <span
            key={tagName}
            className="block w-full md:w-auto text-lg md:my-auto pr-5 font-bold md:pl-6 text-slate-500"
          >
            <FM id={`${model}.${tagName}`} />
          </span>
          <div className="my-auto space-y-1">
            {Object.entries(values).map(([valName, color]) =>
              tagLabel(valName, color),
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default SectionRowTags;
