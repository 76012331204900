import React from "react";

import { useQuery } from "@tanstack/react-query";
import proxy from "api/proxy";
import Board from "components/Meta/Board";
import saleOrder from "./ModelSale";

const SaleOrder = ({ config }) => {
  const { data: modules_activated } = useQuery(
    ["modules_activated"],
    async () => {
      const { data: modules } = await proxy.search(
        "ir.module",
        [
          ["name", "in", ["sale_discount_flex", "sale_packing_unit"]],
          ["state", "=", "activated"],
        ],
        ["name"],
      );

      return modules?.map((item) => {
        return item.name;
      });
    },
  );
  const config_ = { ...config, modules_activated: modules_activated };

  return <Board ctxView={saleOrder.ctxView(config_)} />;
};

export default SaleOrder;
