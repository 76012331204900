import React from "react";

const searchUnit = (value, record) => {
  let dom = [["category", "=", record?.unit?.category]];
  if (value) {
    dom.push(["name", "ilike", `%${value}%`]);
  }
  return dom;
};

const getAmountDiscount = (record) => {
  if (record?.quantity && record?.discount_amount) {
    return record.quantity * record.discount_amount;
  }
};

const getDiscount = (record) => {
  if (record?.discount_rate) {
    return (record.discount_rate * 100).toFixed(2).toString() + " %";
  }
};

const onChangeAmount = (activeRecord) => {
  let upToStore = {};
  let upToActive = {};
  if (activeRecord.amount && activeRecord.quantity) {
    const { amount, quantity } = activeRecord;
    upToActive.total_amount = amount * quantity;
    upToActive.unit_price = amount;
  }
  return [upToStore, upToActive];
};

const accDelete = (rec) => {
  let res = true;
  if (rec && rec.state !== "draft") {
    res = false;
  }
  return res;
};

const getView = () => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    form_action_add: "modal",
    model: "sale.line",
    row_selectable: false,
    table_action: ["delete"],
    form_action: ["add", "delete"],
    access: {
      delete: accDelete,
      edit: accDelete,
    },
    otherActions: [],
    selectable: true,
    webfields: {
      product: {
        type: "many2one",
        model: "product.product",
        attrs: ["id", "list_price", "name"],
        required: true,
      },
      "product.code": {
        type: "char",
        readOnly: true,
      },
      unit: {
        type: "many2one",
        model: "product.uom",
        recSearch: searchUnit,
      },
      quantity: {
        type: "numeric",
        required: true,
        default: 1,
      },
      base_price: {
        type: "numeric",
      },
      discount_amount: {
        type: "numeric",
        readOnly: true,
        decimalPlaces: 0,
        function: getAmountDiscount,
        search: true,
      },
      discount_rate: {
        type: "char",
        readOnly: true,
        function: getDiscount,
        search: true,
      },
      status_order: {
        type: "char",
        readOnly: true,
        default: "draft",
        translate: true,
      },
      unit_price: { type: "numeric" },
      sale_price_taxed: { type: "numeric" },
      amount: { type: "numeric", readOnly: true, withChange: onChangeAmount },
      "origin.time_start": { type: "char" },
      "origin.time_end": { type: "char" },
      origin: { type: "many2one", readOnly: true },
      add_discount: {
        type: "button",
        // method: () => {
        //   return <p>hola mundo</p>;
        // },
        color: "blue",
        icon: "fi fi-rr-add",
        // visible: visibleDiscount,
      },
    },
    webtree: [
      { name: "product", width: "40%" },
      { name: "origin.time_start", width: "23%" },
      { name: "origin.time_end", width: "23%" },
      { name: "quantity", width: "10%" },
      { name: "base_price", width: "23%" },
      { name: "discount_rate", width: "23%" },
      { name: "discount_amount", width: "23%" },
      { name: "amount", width: "23%" },
      { name: "status_order", width: "10%" },
      { name: "add_discount", width: "40%" },
    ],
    webform: [
      { name: "product" },
      { name: "unit" },
      { name: "quantity" },
      { name: "amount" },
    ],
  };
  return DictCtxView;
};

export default { ctxView: getView };
