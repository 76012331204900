import React, { Fragment } from "react";
import FormAddRentalBooking from "./FormAddRentalBooking";
import { Link, useParams } from "react-router-dom";
// import Logo from "../../assets/img/logo_original.png";
import RentalRequirements from "./RentalRequirements";
import BookingMap from "./BookingMap";
import {
  ArrowRightIcon,
  ChatBubbleOvalLeftIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";
import { MapIcon, GlobeAltIcon } from "@heroicons/react/24/solid";
import StdButton from "components/Buttons/StdButton";
import BookingConfirmation from "./BookingConfirmation";
const Logo = "https://renagew.com/wp-content/uploads/2017/06/logo88.png";
const BACKGROUND =
  "https://renagew.com/wp-content/uploads/2023/05/20190908_085107-e1685533096893.jpg";

const STYLE_ITEM_CONTACT = "flex flex-col items-center gap-3";
const WebBookingRental = (props) => {
  const { id } = useParams();
  // console.log(id);

  return (
    <div className="grid md:grid-cols-2 h-screen">
      <img src={Logo} className="w-52 absolute top-3" />

      <div className="md:p-10 p-5 flex items-center">
        {id ? (
          <BookingConfirmation id={id} />
        ) : (
          <FormAddRentalBooking {...props} id={id} />
        )}
      </div>
      <div
        className={`relative md:flex ${!id ? "md:px-20" : "px-0"} items-center`}
        style={{ backgroundImage: BACKGROUND, background: BACKGROUND }}
      >
        {!id ? (
          <Fragment>
            <RentalRequirements />

            <img
              src="https://renagew.com/wp-content/uploads/2023/05/20190908_085107-e1685533096893.jpg"
              className="w-full left-0 top-0 h-full object-cover absolute z-0"
            />
          </Fragment>
        ) : (
          <Fragment>
            <div className="space-y-10 w-full md:h-full bg-gray-100 p-10">
              <h1 className="block text-2xl">Our addresses </h1>

              <div className="flex justify-start items-center space-x-5">
                <MapIcon className="w-20 bg-gray-200 rounded-full p-4" />
                <span className="max-w-[300px]">
                  Monday to Friday 1 pm to 4 pm 48 Warren St, Fortitude Valley
                  QLD 4006
                  <a
                    href="https://g.page/renagewaustralia?share"
                    target="_blank"
                    className="mt-2  text-blue-presik underline flex"
                  >
                    <span>See map</span> <ArrowRightIcon className="w-4" />
                  </a>
                </span>
              </div>
              <div className="flex justify-start items-center space-x-5">
                <MapIcon className="w-20 bg-gray-200 rounded-full p-4" />
                <span className="max-w-[300px]">
                  Monday to Friday 1 pm to 4 pm Centrepoint Mall, Shop 4/3290
                  Surfers Paradise Blvd, Surfers Paradise QLD 4217
                  <a
                    href="https://g.page/RENAG_GOLD_COAST?share"
                    target="_blank"
                    className="mt-2  text-blue-presik underline flex"
                  >
                    <span>See map</span> <ArrowRightIcon className="w-4" />
                  </a>
                </span>
              </div>
              <Link to="https://renagew.com/contac/">
                <StdButton
                  size={"w-60"}
                  content={"visit our website"}
                  color="bluePresik"
                  style="mx-auto mt-10 space-x-5 uppercase"
                  onClick={() => null}
                >
                  <GlobeAltIcon className="w-6 relative -left-4" />
                </StdButton>
              </Link>
              <p className="text-xs text-gray-500 mt-10 text-left">
                What you should take into account before arriving at our
                facilities
              </p>
            </div>
            <div className="flex md:absolute w-full left-0 z-10 bg-blue-presik bottom-0 md:h-[10vh] text-white align-middle items-center py-3 md:px-10">
              <ul className="md:flex justify-between w-full ">
                <li className={STYLE_ITEM_CONTACT}>
                  <ChatBubbleOvalLeftIcon className="w-8 text-center" /> SMS :
                  0414 644 667
                </li>
                <li className={STYLE_ITEM_CONTACT}>
                  {" "}
                  <ChatBubbleOvalLeftIcon className="w-8 text-center" /> 0414
                  644 667
                </li>
                <li className={STYLE_ITEM_CONTACT}>
                  <EnvelopeIcon className="w-8 text-center" /> sales@renagew.com
                </li>
                <li className={STYLE_ITEM_CONTACT}>
                  <EnvelopeIcon className="w-8 text-center" /> renagewaustralia
                </li>
              </ul>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default WebBookingRental;
