import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

/**
 *
 * @param {object} props - component props
 * @param {boolean} props.open - true or false
 * @param {object} props.onClose - object record to show
 * @return {Component} - Modal Form for show form record
 */
function PureModal({
  children,
  open,
  onClose,
  title,
  btnCloseActive,
  width = false,
  backdrop = false,
}) {
  if (!width) {
    width = "sm:w-5/6 md:max-w-4xl";
  }
  let btnClose;
  if (btnCloseActive) {
    btnClose = (
      <XMarkIcon
        onClick={onClose}
        className="h-6 w-6 text-zinc-600 stroke-2 cursor-pointer hover:text-rose-700 my-auto ml-auto"
        aria-hidden="true"
      />
    );
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        id="pure-modal-form"
        as="div"
        className="relative z-20"
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0  bg-opacity-75 transition-opacity  bg-gray-600 ${
              backdrop
                ? "backdrop-blur-sm transform transition-all duration-100 !bg-black/70"
                : null
            }`}
          />
        </Transition.Child>

        <div className="fixed inset-0 z-auto overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-visible rounded-xl bg-stone-100 text-left shadow-xl transition-all sm:my-8 ${width}`}
              >
                {title && (
                  <div className="flex px-5 py-6 justify-between">
                    {title}
                    {btnClose}
                  </div>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default PureModal;
