import React, { useState } from "react";
import date from "date-and-time";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import CalendarContext from "./CalendarContext";
import CalendarHeader from "./CalendarHeader";
import CalendarHeaderRow from "./CalendarHeaderRow";
import CalendarBody from "./CalendarBody";
import ModalForm from "components/Modals/ModalForm";
import { useMethod } from "hooks/records";
import mainStore from "store/mainStore";
import { useWizardStore } from "store/wizardStore";
import helper from "./helper";

const fmtDate = "YYYY-MM-DD";

function CalendarTable(props) {
  const { rooms, selectedDate, reload, viewerUser } = props;
  let [openFolio, setOpenFolio] = useState(false);
  let [view, setView] = useState(null);
  const { setRecord } = mainStore();

  const dates = fillUpDates();

  const selectedDateFmt = date.format(selectedDate.value, fmtDate);
  const ctxArgs = {
    model: "hotel.folio",
    method: "get_rack",
    args: [{ start_date: selectedDateFmt }],
  };

  const { data, refetch } = useMethod(ctxArgs);
  const { setTrigger } = useWizardStore.getState();
  setTrigger(refetch);
  let rack = new Map();
  if (data) {
    data.rooms.map((room) => {
      rack.set(room["id"], []);
    });

    data.folios.map((folio) => {
      if (!folio["room."]) {
        return;
      }
      const room_id = folio["room."].id;
      let roomFo = rack.get(room_id);
      roomFo.push(helper.convertFolio(folio));
    });
    data.mnts.map((mnt) => {
      const room_id = mnt["room."].id;
      let roomMnt = rack.get(room_id);
      roomMnt.push(helper.convertOpMant(mnt));
    });
    data.folios;
  }

  const ctxPrices = {
    model: "hotel.rate_plan",
    method: "get_prices_by_accommodation",
    args: [{ start_date: selectedDateFmt }],
  };
  const { data: prices } = useMethod(ctxPrices);
  // const prices = {};

  function onChangeDate(field, value) {
    let value_ = date.parse(value, fmtDate);
    selectedDate.value = value_;
  }

  function fillUpDates() {
    // Fill up dates in component state
    let dates = [];
    const day = date.addDays(selectedDate.value, -4);
    for (let x = 0; x < 34; x++) {
      dates.push(date.addDays(day, x));
    }
    return dates;
  }

  function actionMoveBooking(singleBooking, room, newStartDate, newEndDate) {
    let allBookings = helper.moveBooking(
      singleBooking,
      room,
      newStartDate,
      newEndDate,
    );
    if (allBookings === false) {
      console.log("Unable to move to target date");
    }
  }

  function actionCreateBooking(singleBooking) {
    if (
      helper.canExistBooking(
        singleBooking,
        singleBooking.room_id,
        singleBooking.from_date,
        singleBooking.to_date,
      )
    ) {
    } else {
      console.log("Cannot create booking");
    }
  }

  function actionCanExistBooking(
    singleBooking,
    room,
    newStartDate,
    newEndDate,
  ) {
    return helper.canExistBooking(
      singleBooking,
      room,
      newStartDate,
      newEndDate,
    );
  }

  async function actionOpenPopup(recId, model, ctxView) {
    if (!ctxView) return;
    setView(ctxView);
    setRecord(recId, model, ctxView);
    setOpenFolio(true);
  }

  function actionClosePopup() {
    setOpenFolio(false);
  }

  // create context, to make data available to other child components
  const contextValue = {
    data: {}, // ????
    actionMoveBooking: actionMoveBooking,
    actionCanExistBooking: actionCanExistBooking,
    actionOpenPopup: actionOpenPopup,
    actionClosePopup: actionClosePopup,
    actionCreateBooking: actionCreateBooking,
  };

  return (
    <CalendarContext.Provider value={contextValue}>
      <div className="r-calendar mx-6">
        <DndProvider backend={HTML5Backend}>
          <table
            id="hotel-rack-calendar"
            className="mb-12 border-solid border border-gray-400 r-calendar-main-table"
          >
            <thead className="sticky top-0">
              <CalendarHeader dates={dates} />
              {data && (
                <CalendarHeaderRow
                  title={"OCC. RATE"}
                  attr={"occ_rate"}
                  data={Object.values(data.occ_rate)}
                />
              )}
            </thead>
            <CalendarBody
              rack={rack}
              dates={dates}
              rooms={rooms}
              prices={prices}
            />
          </table>
        </DndProvider>
        {!viewerUser && openFolio && (
          <ModalForm
            open={openFolio}
            ctxView={view}
            level="main"
            onClose={() => setOpenFolio(false)}
          />
        )}
      </div>
    </CalendarContext.Provider>
  );
}

export default CalendarTable;
