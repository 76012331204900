import React, { useState } from "react";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";

import DateRangeCard from "components/Tools/DateRangeCard";
import DateRangeCustomSelect from "./DateRangeCustomSelect";

let numItems = 3;
if (window.innerWidth > 1600) {
  numItems = 5;
} else if (window.innerWidth > 1366) {
  numItems = 4;
} else if (window.innerWidth > 970) {
  numItems = 3;
}

const DateRangeFilter = ({ action, styleContent = false }) => {
  let [selectedDate, setSelectedDate] = useState(null);
  let [today, setToday] = useState(new Date());

  const getDaysArray = (start, end) => {
    const daysArray = [];
    let currentDate = new Date(start);
    end = new Date(end);

    while (currentDate <= new Date(end)) {
      daysArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return daysArray;
  };
  let startDate = new Date(today);
  startDate.setDate(today.getDate() - numItems);
  let endD = new Date(today);
  endD.setDate(today.getDate() + numItems);
  const daysBetweenDates = getDaysArray(startDate, endD);

  const handleClick = (date_) => {
    setSelectedDate(date_);
    action(date_);
  };

  const handleRangeDays = (direction) => {
    let _today = today;
    if (direction == "previous") {
      _today.setDate(_today.getDate() - numItems);
    } else {
      _today.setDate(_today.getDate() + numItems);
    }
    setToday(_today);
    action(_today);
  };

  return (
    <div id="date-range" className={`grid mt-3 ${styleContent}`}>
      <ul className="flex flex-row space-x-4 justify-center">
        <li className="flex">
          <ArrowLeftCircleIcon
            className="w-10 text-gray-800 cursor-pointer"
            onClick={() => handleRangeDays("previous")}
          />
        </li>
        {daysBetweenDates.map((date_, index) => (
          <DateRangeCard
            key={index}
            date_={date_}
            selected={selectedDate}
            onClick={handleClick}
          />
        ))}
        <DateRangeCustomSelect onClick={handleClick} />
        <li className="flex">
          <ArrowLeftCircleIcon
            className="w-10 text-gray-800 transform rotate-180 cursor-pointer"
            onClick={() => handleRangeDays("next")}
          />
        </li>
      </ul>
    </div>
  );
};

export default DateRangeFilter;
