import React, { useState, useEffect } from "react";
import { FormattedMessage as FM } from "react-intl";
import { useNavigate } from "react-router-dom";

import StdButton from "components/Buttons/StdButton";
import LabelRowSection from "./LabelRowSection";
import FolioCard from "./FolioCard";

function BookingPage({ booking }) {
  let [confirmDisabled, setConfirmDisabled] = useState(true);
  const navigate = useNavigate();

  function getFinish() {
    let foliosWithGuests = [];
    for (const res of booking.lines) {
      if (res) {
        const folio = res[1];
        foliosWithGuests.push(folio.guests.length);
      }
    }
    const active = foliosWithGuests.every((val) => val > 0);
    setConfirmDisabled(!active);
  }

  useEffect(() => {
    getFinish();
  }, [booking]);

  let folios = [];
  booking.lines.forEach((folio) => {
    folios.push(<FolioCard key={folio.id} folio={folio} />);
  });

  async function finish() {
    navigate("success");
  }

  const channel = booking.channel.name ?? "direct";

  return (
    <div className="mb-12">
      <LabelRowSection
        label="app.webcheckin.no_booking"
        data={booking.number}
      />
      <LabelRowSection
        label="app.webcheckin.customer"
        data={booking.party.name}
      />
      <LabelRowSection
        label="app.webcheckin.channel"
        translate={true}
        data={`app.webcheckin.${channel}`}
      />
      <p className="mt-15 font-lg text-gray-500">
        <FM id="webcheckin_booking_page" />
      </p>
      <div className="pb-3">{folios}</div>
      <StdButton
        content="app.webcheckin.finish"
        size="w-full"
        color="blue"
        disabled={confirmDisabled}
        onClick={() => finish()}
      />
    </div>
  );
}

export default BookingPage;
