import React from "react";

import FormField from "./FormField";
import { classNames, colors } from "tools/ui";
import upStore from "store/upStore";

const base =
  "rounded-none w-full border-t text-center border-b border-slate-300 py-2 px-3 text-gray-700 focus:outline-none focus:border focus:border-cyan-500 ";
const iconStyle = "w-7 h-7 text-stone-700";
const stBtn =
  "flex text-xl text-zinc-500 border-slate-300 border-[1px] px-3 py-1 focus:outline-none";
const leftBtn = "rounded-l-md";
const rightBtn = "rounded-r-md";

function IntegerField(props) {
  const { field, data, attrs } = props;
  const { name, readOnly } = field;
  const { level } = attrs;
  let btnBgColor = "bg-amber-100 active:bg-amber-200";
  if (readOnly) {
    btnBgColor = "bg-gray-300";
  }

  function onChange(value) {
    let _value = value.toString().replace(/\./g, "");
    if (isNaN(_value)) {
      return;
    }
    _value = Number(_value);
    data.value = _value;
    upStore(level, {
      fieldName: name,
      value: _value,
      field: field,
    });
  }

  let _color = "bg-white";
  if (field.color === "gray") {
    _color = "bg-gray-100";
  }
  if (readOnly) {
    _color = colors.readOnly;
  }

  const changeValue = (e, sign) => {
    e.preventDefault();
    if (readOnly) return;
    if (sign === "+") {
      data.value += 1;
    } else {
      if (data.value > 0) {
        data.value -= 1;
      }
    }
    onChange(data.value);
  };

  const btnMinus = (
    <button
      className={classNames(stBtn, leftBtn, btnBgColor)}
      onClick={(e) => changeValue(e, "-")}
    >
      <i class="font-extrabold flex fi fi-br-minus-small my-auto" />
    </button>
  );

  const btnPlus = (
    <button
      className={classNames(stBtn, rightBtn, btnBgColor)}
      onClick={(e) => changeValue(e, "+")}
    >
      <i class="font-extrabold flex fi fi-br-plus-small my-auto" />
    </button>
  );

  const _value = data.value ? data : 0;
  return (
    <FormField {...field}>
      <div className="flex items-stretch w-full h-10">
        {btnMinus}
        <input
          id={name}
          name={name}
          min="0"
          type="number"
          value={_value}
          className={classNames(base, _color)}
          onChange={(e) => onChange(e.target.value)}
          readOnly={readOnly}
        />
        {btnPlus}
      </div>
    </FormField>
  );
}

export default IntegerField;
