const formatter = Intl.NumberFormat("en-US");

function getLabel(field, typeView, model) {
  let label = field.name;
  if (typeView !== "tree") {
    label = `${model}.${label}`;
  }
  return label;
}

function getRequired(field, record) {
  let required = field.required || false;
  if (typeof field.required === "function") {
    required = field.required(record);
  }
  return required;
}

function getReadOnly(field, record, parentRec) {
  let readOnly = false;
  if (parentRec && parentRec.readOnly) {
    readOnly = true;
  } else if (typeof field.readOnly === "boolean") {
    readOnly = field.readOnly;
  } else if (record && typeof field.readOnly === "function") {
    readOnly = field.readOnly(record);
  } else if (record && field.readOnly) {
    for (const [keyField, valuesField] of Object.entries(field.readOnly)) {
      if (valuesField.includes(record[keyField])) {
        readOnly = true;
      }
    }
  }
  return readOnly;
}

function getValue(field, record) {
  let value = "";
  if (record && record[field.name] !== undefined) {
    value = record[field.name];
  } else if (typeof field.function === "function") {
    value = field.function(record, field);
  } else if (field.default) {
    if (record.id <= 0 || !record.id) {
      if (typeof field.default === "function") {
        // Insert here activeRecord or childRecord
        value = field.default();
      } else {
        value = field.default;
      }
    }
  }
  if (value && field.type === "numeric" && value !== "") {
    value =
      typeof value === "string" ? value : formatter.format(value.toString());
  }
  return value;
}

function getVisible(field, record) {
  let isVisible = true;
  if (record && field.visible) {
    isVisible = field.visible(field.name, record);
  }
  return isVisible;
}

export default { getVisible, getValue, getReadOnly, getRequired, getLabel };
