import React, { useContext, useState } from "react";
// import { useDrag } from "react-dnd";

import Popup from "./Popup";
import PopupHeader from "./PopupHeader";
import PopupContent from "./PopupContent";
import CalendarContext from "./CalendarContext";
import {
  borderColor,
  tagsColor,
  tagsColorText,
  channelsImg,
} from "./constants";
import { classNames } from "tools/ui";
import ModelFolio from "apps/HotelRack/ModelFolio";
import ModelMaintenance from "apps/HotelRack/ModelMaintenance";

const base =
  "flex rounded-full h-8 border-[1px] pl-1 mx-1 border active:scale-95";

const ctxViewFolio = ModelFolio.ctxView();
const ctxViewMaint = ModelMaintenance.ctxView();

function RackRecord(props) {
  // load default context
  const { operation } = props;
  const context = useContext(CalendarContext);
  const [openPopup, setOpenPopup] = useState(false);

  const getContent = (duration) => {
    // get inner content of the booking
    let label;
    if (operation.kind === "accommodation") {
      // Fixme why this values are ""
      if (operation.guest_name && operation.guest_name !== "") {
        label = operation.guest_name;
      } else if (operation.party !== "" && operation.party.name) {
        label = operation.party.name;
      } else {
        label = operation.contact;
      }
      if (label && label !== "") {
        if (duration > 1) {
          label = label.slice(0, 6 * duration);
        } else {
          label = label.slice(0, 2);
        }
      }
    } else {
      label = operation.room;
    }
    const style = "pl-1 text-xs my-auto !font-mono";
    const colorText = tagsColorText[operation.status];
    return (
      <p className={classNames(style, colorText)} key={operation.id}>
        {label}
      </p>
    );
  };

  const handleMouse = (value) => {
    setOpenPopup(value);
  };

  const onClick = (event, operation) => {
    let model = "hotel.folio";
    let ctxView = ctxViewFolio;
    if (operation.kind === "maintenance") {
      model = "hotel.maintenance";
      ctxView = ctxViewMaint;
    }
    context.actionOpenPopup(operation.id, model, ctxView);
    event.stopPropagation();
    event.preventDefault();
  };

  const { duration } = operation;
  if (duration <= 0) return null;

  if (operation.channel_code === "") {
    operation.channel_code = "house";
  }
  let _duration = duration * 4.692 + (duration - 1) * 0.27;
  const _color = tagsColor[operation.status];
  let channelImg = operation.channel_code
    ? channelsImg[operation.channel_code]
    : null;

  return (
    <div
      onClick={(e) => onClick(e, operation)}
      className={classNames(base, _color)}
      onMouseEnter={() => handleMouse(true)}
      onMouseLeave={() => handleMouse(false)}
      style={{ width: _duration + "rem" }}
    >
      {channelImg ? (
        <div className="rounded-full w-6 h-6 py-1">
          <img src={channelImg} />
        </div>
      ) : null}
      {getContent(duration)}
      <Popup
        open={openPopup}
        header={<PopupHeader record={operation} />}
        handleMouseOut={handleMouse}
      >
        <PopupContent operation={operation} />
      </Popup>
    </div>
  );
}

export default RackRecord;
